import styled from "styled-components";
import { palette } from "styled-theme";

const LoaderComponent = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  span {
      text-align: center;
      font-size: 12px;
      color: ${palette("text", 2)};
  }
  
  .ant-alert-message {
    font-size: 12px;
  }
  
  .ant-btn {
    margin-top: 8px;
  }

  .errorContent {
    width: 50px;
    height: 50px;
    animation: svgSpinner 1.4s linear infinite;
  }

  .contentLoader {
    width: 50px;
    height: 50px;
    animation: svgSpinner 1.4s linear infinite;
  }

  .contentLoaderCircle {
    animation: svgSpinnerCircle 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0px;
    stroke: ${palette("secondary", 13)};
    stroke-linecap: round;
  }

  @keyframes svgSpinner {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes svgSpinnerCircle {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0px;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -120px;
    }
  }
  
  p {
    padding:0.5em 0 0 1em;
  }
  
  .k-loader {
	display:inline-block;
	font-size:2em;
	margin:0 .5em;
	position:relative;
	height:.5em;
	width:1em
  }
  .k-loader:before,.k-loader:after {
    content:"";
    display:block;
    height:.4em;
    position:absolute;
    width:.4em
  }
  .k-loader:before {
    animation:k-loadingK 2s ease-in-out infinite,k-loadingM .66s ease-in-out infinite;
    background:${palette("secondary", 13)};
  }
  .k-loader:after {
    animation:k-loadingL 2s ease-in-out infinite,k-loadingN .66s ease-in-out infinite;
    background:${palette("secondary", 0)};
  }
  .k-small {
	font-size:1em
  }
  .k-large {
    font-size:4em
  }
  .k-circle:before,.k-circle:after {
    border-radius:50%
  }
  .k-circle-before:before {
    border-radius:50%
  }
  .k-circle-after:after {
    border-radius:50%
  }
  
  @keyframes k-loadingK {
	0% {
        left:0;
        transform:scale(1.1)
    }
    50% {
        left:100%;
        transform:scale(1)
    }
    100% {
        left:0;
        transform:scale(1.1)
    }
  }

  @keyframes k-loadingL {
	0% {
        left:100%;
        transform:scale(1.1)
    }
    50% {
        left:0;
        transform:scale(1)
    }
    100% {
        left:100%;
        transform:scale(1.1)
    }
  }

`;

export default LoaderComponent;
