import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    active_level: false,
    popular_sections: false,
    talk_of_town: false,
    hot_posts: false,
    hot_words:false
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.STATUS_OF_TALK_TOWN:
            return state
                .set('popular_sections', true)
        case types.STATUS_OF_ACTIVE_LEVEL:
            return state
                .set('active_level', true)
        case types.STATUS_OF_POPULAR_SECTIONS:
            return state
                .set('popular_sections', true)

        case types.STATUS_OF_HOT_POSTS:
            return state
                .set('hot_posts', true)

        case types.STATUS_OF_HOT_WORDS:
            return state
                .set('hot_words', true)

        default:
            return state;
    }
}