import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
  posts:[],
  topic:{}
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.UPDATE_TALK_TOWN:
          return  state
                    .set('posts', action.data.posts)
                    .set('topic', action.data.topic);
       
        default:
            return state;
    }
}