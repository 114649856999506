import { createTransform } from 'redux-persist';
import { Map } from 'immutable'
import { Table } from '../utils/structUtil';
import { compress, decompress } from '../utils/compressUtil';

// optionally convert this object into a JS object if it is Immutable
const fromImmutable = (raw) => Map.isMap(raw) ? raw.toJS() : raw;

// convert this JS object into an Immutable object
const toImmutable = (state) => Map.isMap(state) ? state : Map(state);

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        let row = fromImmutable(inboundState);

        switch(key) {
            case 'ConfigurationTable':
                row && Object.keys(row).map(k => row[k] = row[k].toObject());
                break;
            default:
                break;
        }
        return compress(JSON.stringify(row));
    },
    // transform state being rehydrated
    (outboundState, key) => {

        let state = toImmutable(JSON.parse(decompress(outboundState)));
        
        switch(key) {
            case 'ConfigurationTable':
                state.map((v, k) => state = state.set(k, new Table(v)));
                break;
            default:
                break;
        }
        return state;
    },
);

export default SetTransform;