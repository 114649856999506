import qs from 'qs';
import {isUuid} from '../utils/utility';
import {store} from '../store/store';

export function getInitData() {
  const initData = qs.parse(window.location.search.slice(1));
  if (initData.toggle)
    initData.toggle.free_shipping =
      initData.toggle.free_shipping === 'true' ? true : undefined;
  return initData;
}
export function setUrl(searchState) {
  const search = searchState
    ? `${window.location.pathname}?${qs.stringify(searchState)}`
    : '';
  window.history.pushState(searchState, null, search);
}

export function getDefaultOpenKeys() {
    const stores = store.getState();
    const allMenus = stores['Auth'].get('menu');
    Object.keys(allMenus).map( k => {
        this.defaultOpenKeys[k] = [];
        const menus = allMenus[k];

        menus.sub && menus.sub.map( (menu, key) => {
            if(menu.sub && menu.sub.length > 0) {
                this.defaultOpenKeys[k].push(`${k}_${key}`)
            }
        });
    });
}

//路径处理
export function getDefaultPath() {
  if (window && window.location.pathname) {

    let pathname = window.location.pathname;
    if(pathname.lastIndexOf('/') === pathname.length-1) {
        pathname = pathname.substr(0,pathname.length-1);
    }

    const routes = pathname.split('/');
    if(routes.length >= 3){
        const currentKey = [...routes].slice(0, 3).join('/');

        const currentSubKey = [...routes];
        if(isUuid(currentSubKey[currentSubKey.length-1])) {
            currentSubKey.pop();
        }
        return {
            currentKey: [ currentKey ],
            currentSubKey: [ currentSubKey.join('/') ],
            currentPathKey: routes[2]
        }
    }
  }
  return {
      currentKey: [],
      currentSubKey: [],
      currentPathKey: null
  };
}

export function getPath(pathname) {
    if(pathname.lastIndexOf('/') === pathname.length-1) {
        pathname = pathname.substr(0,pathname.length-1);
    }
    const routes = pathname.split('/');
    if(routes.length >= 3){
        const currentKey = [...routes].slice(0, 3).join('/');

        const currentSubKey = [...routes];
        if(isUuid(currentSubKey[currentSubKey.length-1])) {
            currentSubKey.pop();
        }
        return {
            currentKey: [ currentKey ],
            currentSubKey: [ currentSubKey.join('/') ],
            currentPathKey: routes[2]
        }
    }
    return {
        currentKey: [],
        currentSubKey: [],
        currentPathKey: null
    };
}

export function hasSubDrawer() {
  const exclusion = [
    'license', 'tax', 'monitoring', 'report', 'ledger', 'audit'
  ];
  if (window && window.location.pathname) {
      const routes = window.location.pathname.split('/');
      if(exclusion.indexOf(routes[2]) > -1) {
        return true;
      }
  }
  return  false;
}
