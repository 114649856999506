import ReduxPersist from '../config/reduxPersist';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const updateReducers = (store: Object) => {
    let persistor = persistStore(store);

    const reducerVersion = ReduxPersist.reducerVersion;

    // Check to ensure latest reducer version
    storage.getItem('reducerVersion').then((localVersion) => {
        if (localVersion !== reducerVersion) {
            // Purge store
            persistStore(store, null, () => {
                persistStore(store);
            }).purge([]);
            storage.setItem('reducerVersion', reducerVersion);
        }
    }).catch(() => {
        storage.setItem('reducerVersion', reducerVersion);
    });

    return persistor;
};

export default { updateReducers }