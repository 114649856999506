import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getPath} from '../helpers/urlSync';
import {changeCurrent} from '../actions/app';

let redirect = false;

class AppAuth extends React.Component {

    constructor(props) {
        super(props);
        this.checkAppAuth = this.checkAppAuth.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { location, changeCurrent } = this.props;
        if(location.pathname !== prevProps.location.pathname){
            //变化路径显示
            changeCurrent(getPath(location.pathname));
            //权限检查
            this.checkAppAuth();
        }
    }

    checkAppAuth() {
        const { rights, location } = this.props;
        const path = location.pathname.split('/');
        const currentRight = `${path[2]}_${path[3]}`;

        if(rights && rights.indexOf(currentRight) === -1) {
            //redirect = true;
            //history.push('/noAuthorized');
        }
    }

    shouldComponentUpdate(nextProps) {
        if ( redirect ) {
            redirect = false;
            return false;
        }
        else {
            return true;
        }
    }

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.Auth.get('rights'),
        location: state.router.location
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({changeCurrent}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAuth);
