import { createStore, applyMiddleware, compose } from "redux"
import thunk from 'redux-thunk';
import Rehydration from "../helpers/rehydration"
import createSagaMiddleware from "redux-saga"

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
        : compose;

export default (rootReducer, rootSaga, routeMiddleware) => {

    /* ------------- Redux Configuration ------------- */
    const middleware = [thunk];
    //const enhancers = [];

    /* -------------  Route Middleware ------------- */
    middleware.push(routeMiddleware);

    /* ------------- Saga Middleware ------------- */
    const sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);

    //enhancers.push(composeEnhancers(applyMiddleware(...middleware)));

    /* ------------- Assemble Middleware ------------- */
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
    const persistor = Rehydration.updateReducers(store);

    let sagasManager = sagaMiddleware.run(rootSaga);

    return {
        store,
        sagasManager,
        sagaMiddleware,
        persistor
    }
}