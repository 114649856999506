import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from '../../config/style-util';
import WithDirection from '../../config/withDirection';

const TopbarWrapper = styled.div`    
height:1.6rem;
width:100%;
box-shadow:0px 0px 0.12rem #345480;
  .topbar {
    // display: flex;
    // justify-content: space-between;
    background-color: #ffffff;
    z-index: 1000;
    ${transition()};
    &.ant-layout-header {
        height:100%;
        padding: 0;
        width: 100%;
    }
    

    
    &.consoleTopbar {
            .header_top {
              height:37%;
              width:100%;
              padding:0 5.48rem;
              display:flex;
              span{
              color:#345480;

              }
              .top_left {
                width:100%;
                display:inline-block;
                text-align:center;
                font-size:0.24rem;
                font-weight:600;
                line-height:0.33rem;
                // margin-right:0.2rem;
                margin-top:0.10rem;
              }
              .top_right {
                font-size:0.14rem;
              }
            }
          
            .header_content {
              height:63%

              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              align-content: flex-start;
              justify-content: space-between;

              margin : 0 1.2rem;
              margin-top:.24rem;
              padding-bottom:.2rem;

              .block_selected {

             
              .first_title {
                  border-bottom : 0.03rem solid #345480;
                  transition: all .5s;
                  color:#345480 !important;
                  transition: color .5s;
              }

              .second_title {
                color:#345480 !important;
                transition: color .5s;
              }
              }


              .block_content {

               

                :hover {
                  cursor:pointer
                  p{
                      color:#345480
                      transition: color .5s;
                  }
                  .first_title {
                      border-bottom : 0.03rem solid #345480;
                      transition: all .5s;
                  }
                 
              }

                .first_title {

                  font-size:0.18rem;
                  font-weight:500;
                  height:0.3rem;
                  line-height:0.3rem;
                  // margin-bottom:0.05rem;
                  padding-bottom:0.05rem;
                  color:#999999;
                  text-align:center;
                }
                .second_title {
                  margin-top:0.05rem;
                  height:0.2rem;
                  font-size:0.14rem;
                  font-weight:500;
                  line-height:0.2rem;
                  color:#999999
                  white-space: nowrap;
                  text-align:center
                }
              }
            }



       
        
        h2 {
            padding-left: 0.12rem;
            padding-right: 0.12rem;
            font-size: 0.14rem;
            color: #fff;
        }
        
        .companyDropdown {
            height: 0.5rem;
        }
    }

    
  }    
       
`;

export default WithDirection(TopbarWrapper);
