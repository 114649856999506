import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';
const initState = Map({
    confidence: [],
    non: []
});

export default function taskReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_CONFIDENCE: {
            return state
                .set('confidence', action.data.confidence)
                .set('non', action.data.non)
        }
        default:
            return state;
    }
}