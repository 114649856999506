import { all, takeEvery, put, fork, call, select, actionChannel, take, flush } from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';

import api from '../helpers/api'
import moment from 'moment';

import { optionsConfig } from '../config'
import {
    setError,
    setRequesting,
    clearRequesting,
    clearError
} from '../actions/helper';
/**
 * @param payload
 * {name: '产品信息', type: 'state', uri: '/document/listProduce', action: 'UPDATE_DOCUMENT_PRODUCE'}
 * @returns
 */


export function* requestTalkOfTheTown() {
    yield takeEvery(types.REQUEST_TALK_TOWN, function* (record) {
        yield put(setRequesting());
        let body = { date: record.params.dateTime }
        const rs = yield call(api, 'talkofthetown', body);
        if (!rs.hasOwnProperty('error')) {
            yield put({
                type: types.UPDATE_TALK_TOWN,
                data: rs
            })
            yield put(clearRequesting());
            yield put({ type: types.STATUS_OF_TALK_TOWN });

        } else {
            // yield put(setError({ type: 'error', message: rs.error }));
            yield put(clearRequesting());
        }

    });
}

export function* requestActivitylevel() {
    yield takeEvery(types.REQUEST_ACTIVITY_LEVEL, function* () {
        yield put(setRequesting());

        const rs = yield call(api, 'publicopiniontrend/activitylevel');
        if (!rs.hasOwnProperty('error')) {
            const data = {
                posts: rs[0].data,
                reply: rs[1].data
            }
            yield put({
                type: types.UPDATE_ACTIVITY_LEVEL,
                data: data
            })
            yield put(clearRequesting());
            yield put({ type: types.STATUS_OF_ACTIVE_LEVEL });


        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}


export function* requestPopularSections() {
    yield takeEvery(types.REQUEST_POPULAR_SECTIONS, function* () {
        yield put(setRequesting());

        const rs = yield call(api, 'publicopiniontrend/popularsections');
        if (!rs.hasOwnProperty('error')) {
            const data = {
                article: rs[1].data,
                reply: rs[0].data
            }
            yield put({
                type: types.UPDATE_POPULAR_SECTIONS,
                data: data
            })
            yield put(clearRequesting());
            yield put({ type: types.STATUS_OF_POPULAR_SECTIONS });


        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}

export function* requestHotPosts() {
    yield takeEvery(types.REQUEST_HOT_POSTS, function* (record) {
        yield put(setRequesting());
        const params = record.params;
        let body = { date: params.datetime }
        const rs = yield call(api, 'publicopiniontrend/hotposts', body);
        if (!rs.hasOwnProperty('error')) {
            console.log(rs, 'rs-------')
            yield put({
                type: types.UPDATE_HOT_POSTS,
                data: rs
            })
            yield put(clearRequesting());
        } else {
            if (rs.error === 'NO data for this date[1]') {
                yield put({
                    type: types.UPDATE_HOT_POSTS,
                    data: []
                })
            }
            yield put(clearRequesting());
        }

    });
}

export function* requestHotWords() {
    yield takeEvery(types.REQUEST_HOT_WORDS, function* (record) {
        yield put(setRequesting());
        const params = record.params;
        let body = { date: params.datetime }
        const rs = yield call(api, 'publicopiniontrend/hotwords', body);
        if (!rs.hasOwnProperty('error')) {
            console.log(rs, 'rs-------')

            const data = {
                words: rs[1].data,
                sentiment: rs[0].data
            }
            yield put({
                type: types.UPDATE_HOT_WORDS,
                data: data
            })
            yield put(clearRequesting());

        } else {
            if (rs.error === 'NO data for this date[1]') {
                yield put({
                    type: types.UPDATE_HOT_WORDS,
                    data: {
                        words: [],
                        sentiment: []
                    }
                })
            }
            yield put(clearRequesting());
        }

    });
}



export function* requestSentiment() {
    yield takeEvery(types.REQUEST_SENTIMENT, function* () {
        yield put(setRequesting());
        const rs = yield call(api, 'publicsentimentindex');
        if (!rs.hasOwnProperty('error')) {
            yield put({
                type: types.UPDATE_SENTIMENT_NEWS,
                data: rs
            })
            yield put(clearRequesting());

        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}

export function* requestSwearing() {
    yield takeEvery(types.REQUEST_SWEARING, function* () {
        yield put(setRequesting());
        const rs = yield call(api, 'swearingindex');
        if (!rs.hasOwnProperty('error')) {
            const data = {
                mentions: rs[1].data,
                analysis: rs[0].data
            }
            yield put({
                type: types.UPDATE_SWEARING,
                data: data
            })
            yield put(clearRequesting());

        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}


export function* requestConfidence() {
    yield takeEvery(types.REQUEST_CONFIDENCE, function* () {
        yield put(setRequesting());
        const rs = yield call(api, 'comsumerconfidence');
        if (!rs.hasOwnProperty('error')) {
            const data = {
                confidence: rs[0].data,
                non: rs[1].data
            }
            yield put({
                type: types.UPDATE_CONFIDENCE,
                data: data
            })
            yield put(clearRequesting());

        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}
export function* requestDataSharing() {
    yield takeEvery(types.REQUEST_DATA_SHRAING, function* (record) {
        yield put(setRequesting());
        let params = record.params
        const body = {
            part: params.part
        }
        const rs = yield call(api, 'datasharing', body);
        if (!rs.hasOwnProperty('error')) {
            console.log('rs-----', rs)
            // const data = {
            //     confidence: rs[0].data,
            //     non: rs[1].data
            // }
            // yield put({
            //     type: types.UPDATE_CONFIDENCE,
            //     data: data
            // })
            yield put(clearRequesting());

        } else {
            yield put(setError({ type: 'error', message: rs.error }));
        }

    });
}







export default function* rootSaga() {
    yield all([
        fork(requestTalkOfTheTown),
        fork(requestActivitylevel),
        fork(requestPopularSections),
        fork(requestHotPosts),
        fork(requestHotWords),
        fork(requestSentiment),
        fork(requestSwearing),
        fork(requestConfidence),
        fork(requestDataSharing)
    ]);
}
