import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    apple: {
        'mentions': [],
        'analysis': []
    },
    google: {
        'mentions': [],
        'analysis': []
    },
    microsoft: {
        'mentions': [],
        'analysis': []
    },
    amazon: {
        'mentions': [],
        'analysis': []
    },
    facebook: {
        'mentions': [],
        'analysis': []
    },
    cocaCola: {
        'mentions': [],
        'analysis': []
    },
    samsung: {
        'mentions': [],
        'analysis': []
    },
    disney: {
        'mentions': [],
        'analysis': []
    },
    toyota: {
        'mentions': [],
        'analysis': []
    },
    mcDonald: {
        'mentions': [],
        'analysis': []
    },
    att: {
        'mentions': [],
        'analysis': []
    },
    lv: {
        'mentions': [],
        'analysis': []
    },
    intel: {
        'mentions': [],
        'analysis': []
    },
    nike: {
        'mentions': [],
        'analysis': []
    },
    cisco: {
        'mentions': [],
        'analysis': []
    },

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_INTERNATIONAL_COMPANIES_APPLE: {
            let init = state.get('apple');
            init[action.name] = action.data;
            return state
                .set('apple', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_GOOGLE: {
            let init = state.get('google');
            init[action.name] = action.data;
            return state
                .set('google', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_MICROSOFT: {
            let init = state.get('microsoft');
            init[action.name] = action.data;
            return state
                .set('microsoft', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_AMAZON: {
            let init = state.get('amazon');
            init[action.name] = action.data;
            return state
                .set('amazon', init)
        }

        case types.UPDATE_INTERNATIONAL_COMPANIES_FACEBOOK: {
            let init = state.get('facebook');
            init[action.name] = action.data;
            return state
                .set('facebook', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_COCACOLA: {
            let init = state.get('cocaCola');
            init[action.name] = action.data;
            return state
                .set('cocaCola', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_SAMSUNG: {
            let init = state.get('samsung');
            init[action.name] = action.data;
            return state
                .set('samsung', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_DISNEY: {
            let init = state.get('disney');
            init[action.name] = action.data;
            return state
                .set('disney', init)
        }
        case types.UPDATE_INTERNATIONAL_COMPANIES_TOYOTA: {
            let init = state.get('toyota');
            init[action.name] = action.data;
            return state
                .set('toyota', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_MCDONALD: {
            let init = state.get('mcDonald');
            init[action.name] = action.data;
            return state
                .set('mcDonald', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_ATT: {
            let init = state.get('att');
            init[action.name] = action.data;
            return state
                .set('att', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_LV: {
            let init = state.get('lv');
            init[action.name] = action.data;
            return state
                .set('lv', init)
        }
        case types.UPDATE_INTERNATIONAL_COMPANIES_INTEL: {
            let init = state.get('intel');
            init[action.name] = action.data;
            return state
                .set('intel', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_NIKE: {
            let init = state.get('nike');
            init[action.name] = action.data;
            return state
                .set('nike', init)
        }


        case types.UPDATE_INTERNATIONAL_COMPANIES_CISCO: {
            let init = state.get('cisco');
            init[action.name] = action.data;
            return state
                .set('cisco', init)
        }


        default:
            return state;
    }
}