import Helper from './helper';
import Auth from './authorize';
import Async from './async';
import App from './async';
import requestStatus from './requestStatus';

import talkOfTheTown from './talkOfTheTown';
import activityLevel from './activityLevel';

import popularSections from './popularSections';
import hotPosts from './hotPosts';
import hotWords from './hotWords';
import sentiment from './sentiment';
import swearing from './swearing';
import confidence from './confidence'
import chiefExecutive from './chiefExecutive';
import legCoMembers from './legCoMembers';
import principalOfficials from './principalOfficials';
import chiefCourt from './chiefCourt';

import disciplinedServices from './disciplinedServices';
import governmentBranches from './governmentBranches';
import politicalParties from './politicalParties';
import publicUniversities from './publicUniversities';
import theMedia from './theMedia';

import centralGovernment from './centralGovernment';
import mainlandChina from './mainlandChina';
import mainlandCities from './mainlandCities';
import mainlanders from './mainlanders';

import politicalIssues from './politicalIssues';
import economicIssues from './economicIssues';
import livelihoodIssues from './livelihoodIssues';

import localRetail from './localRetail';
import internationalCompanies from './internationalCompanies';
import mainlandCompanies from './mainlandCompanies';










export default {
    Helper,
    Auth,
    App,
    Async,

    requestStatus,


    talkOfTheTown,
    activityLevel,
    popularSections,
    hotPosts,
    hotWords,
    sentiment,
    swearing,
    confidence,
    chiefExecutive,
    legCoMembers,
    principalOfficials,
    chiefCourt,


    disciplinedServices,
    governmentBranches,
    politicalParties,
    publicUniversities,
    theMedia,

    centralGovernment,
    mainlandChina,
    mainlandCities,
    mainlanders,

    politicalIssues,
    economicIssues,
    livelihoodIssues,

    localRetail,
    internationalCompanies,
    mainlandCompanies





};

export const whitelist = [
   
];
