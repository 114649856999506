const theme = {};

theme.palette = {
  primary: [
    '#4482FF', // 0: Default
    '#3A78F5', // 1: Darken 4%
    '#3775F2', // 2: Darken 5%
    'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
    '#4C8AFF', // 4: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
    '#6AA8FF', // 6: Lighten 15%
    '#63A1FF', // 7: Lighten 12%
    '#3F7DFA', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#5896FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#236cfe', // 12: darken 10%
    '#4d88ff', // 13: Lighten 5%
  ],
  secondary: [
    '#2d3446', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
    '#F9F9FB', // 11: LighterBluish Lighten 3%
    '#1d2539', // 12: DarkBlue Darken 5%
    '#1890FF', // 13: DarkBlue Darken 5%
    '#EAEDF1', // 14:
    '#1890FF', // 15: DarkBlue Lighten 5%
    '#e8f6fb', // 16: LightBluish Darken 10%
    '#f3f3f6', // 17: LightBluish Darken 10%
    '#00a8c1', // 18: DarkBlue Lighten 5%
    '#39edff', // 19: DarkBlue Lighten 5%
  ],
  color: [
    '#FEAC01', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    '#7ED321', // 3: LimeGreen
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
    '#1890FF', // 14: DarkBlue Darken 5%
    '#4ca70a', // 15: LimeGreen
    '#7fbf00', // 16:
    '#0089c4', // 17: Lighten 15%
    '#10deac', // 18: DarkBlue Darken 5%
    '#e6ffb2', // 19: water
    '#cbdff7', // 20: air
    '#fcd6e5', // 21: noise
    '#e8d6c5', // 22: waste
  ],
  pollutant: [
      '#f3ffdd', // 0: water
      '#e6ffb2', // 1: water
      '#e6ffb2', // 2: water
      '#edf6ff', // 3: air
      '#cbdff7', // 4: air
      '#cbdff7', // 5: air
      '#ffecec', // 6: noise
      '#fcd6e5', // 7: noise
      '#fcd6e5', // 8: noise
      '#e8d6c5', // 9: waste
      '#e8d6c5', // 10: waste
      '#e8d6c5', // 11: waste
  ],
  warning: [
    '#ffbf00', // 0: Warning
    '#fff59d', // 1: Warning
  ],
  success: [
    '#00b16a', // 0: Success
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
    '#ffa5a2', // 3: Lighten 8%
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#ffffff', // 5: white
    'rgba(255, 255, 255, 0.65)', // 6: opacity 60%
    'rgba(255, 255, 255, 0.5)', // 7: opacity 60%
    'rgba(120, 129, 149, 0.5)', // 8: opacity 60%
    '#a5a5a5', // 9: Text
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
    'rgba(99, 161, 255, 0.1)', // 5:
    '#79d1ff', // 6
    '#d9d9d9', // 7:
  ],
  background: [
    '#F3F3F3',   //0
    '#FFFFFF'   //1
  ],
  calendar: [
    '#905', // 0:
    '#690', // 1:
    '#a67f59', // 2:
    '#07a', // 3:
    '#dd4a68', // 4:
    '#e90', // 5:
    '#1890FF', // 6:
    '#ff99d0', // 7:
    '#e6ffb2', // 8:
    '#e8d6c5', // 9:
    '#cbdff7', // 10:
    '#fcd6e5', // 11:
    '#eed8b1', // 12:
    '#d7dfee', // 13:
  ],
  state: [
    '#979797', // 0
    '#690', // 1:
    '#e90', // 2:
    '#EC3D3A', // 3
  ],
};

theme.fonts = {
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;
