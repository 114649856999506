//websocket
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const SOCKET_BROKEN = 'SOCKET_BROKEN';
export const SOCKET_PING = 'SOCKET_PING';
export const SOCKET_PONG = 'SOCKET_PONG';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';


//helper actions
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR';
export const CLEAR_MODAL_ERROR = 'CLEAR_MODAL_ERROR';
export const SET_REQUESTING = 'SET_REQUESTING';
export const CLEAR_REQUESTING = 'CLEAR_REQUESTING';
export const SET_SUBMITTING = 'SET_SUBMITTING';
export const CLEAR_SUBMITTING = 'CLEAR_SUBMITTING';
export const SHOW_OPERATE_PANEL = 'SHOW_OPERATE_PANEL';
export const HIDE_OPERATE_PANEL = 'HIDE_OPERATE_PANEL';
export const MOUNT_OPERATE_PANEL = 'MOUNT_OPERATE_PANEL';
export const UNMOUNT_OPERATE_PANEL = 'UNMOUNT_OPERATE_PANEL';


export const REQUEST_CONFIGURATION_TABLE = 'REQUEST_CONFIGURATION_TABLE';
export const UPDATE_CONFIGURATION_TABLE = 'UPDATE_CONFIGURATION_TABLE';

export const REMOVE_OPTION = 'REMOVE_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';
export const REQUEST_OPTION = 'REQUEST_OPTION';
export const QUERY_OPTION = 'QUERY_OPTION';

export const INIT_STEPS = 'INIT_STEPS';
export const NEXT_STEP = 'NEXT_STEP';
export const LAST_STEP = 'LAST_STEP';
export const NEXT_STEP_SET = 'NEXT_STEP_SET';
export const LAST_STEP_SET = 'LAST_STEP_SET';
//app actions
export const FOLD_CHANGE = 'FOLD_CHANGE';
export const FOLD_OPEN_DRAWER = 'FOLD_OPEN_DRAWER';
export const FOLD_OPEN_SUB_DRAWER = 'FOLD_OPEN_SUB_DRAWER';
export const CHANGE_OPEN_KEYS = 'CHANGE_OPEN_KEYS';
export const TOGGLE_ALL = 'TOGGLE_ALL';
export const CHANGE_CURRENT = 'CHANGE_CURRENT';

//authorize actions
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT = 'LOGOUT';
export const NOTLOGIN = 'NOTLOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_UPDATE = 'LOGIN_UPDATE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RECOVER_SUCCESS = 'RECOVER_SUCCESS';
export const UPDATE_MENU_ITEMS = 'UPDATE_MENU_ITEMS';
export const SET_TOKEN = 'SET_TOKEN'

//language switch actions
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const ACTIVATE_LANG_MODAL = 'ACTIVATE_LANG_MODAL';

//theme switch actions
export const CHANGE_THEME = 'CHANGE_THEME';
export const SWITCH_ACTIVATION = 'SWITCH_ACTIVATION';

//async actions
export const SYNC_CHANNEL = 'SYNC_CHANNEL';
export const SYNC_FLUSH = 'SYNC_FLUSH';
export const ASYNC_NOTIFICATION = 'ASYNC_NOTIFICATION';
export const ASYNC_REQUEST = 'ASYNC_REQUEST';
export const ASYNC_RETRY = 'ASYNC_RETRY';
export const SYNC_REFRESH = 'SYNC_REFRESH';
export const SYNC_START = 'SYNC_START';
export const SYNC_STEP = 'SYNC_STEP';
export const ASYNC_FINISHED = 'ASYNC_FINISHED';
export const SYNC_ERROR = 'SYNC_ERROR';
export const ASYNC_CHANNEL = 'ASYNC_CHANNEL';
export const UPDATE_ASYNC_DATA = 'UPDATE_ASYNC_DATA';


//
export const SYNC_OPTION_START = 'SYNC_OPTION_START';
export const SYNC_OPTION_CHANNEL = 'SYNC_OPTION_CHANNEL';
export const REQUEST_OPTIONS = 'REQUEST_OPTIONS'



//new 




export const REQUEST_TALK_TOWN = 'REQUEST_TALK_TOWN'
export const UPDATE_TALK_TOWN = 'UPDATE_TALK_TOWN'
export const STATUS_OF_TALK_TOWN = 'STATUS_OF_TALK_TOWN';

export const REQUEST_ACTIVITY_LEVEL = 'REQUEST_ACTIVITY_LEVEL'
export const UPDATE_ACTIVITY_LEVEL = 'UPDATE_ACTIVITY_LEVEL'
export const STATUS_OF_ACTIVE_LEVEL = 'STATUS_OF_ACTIVE_LEVEL';

export const REQUEST_POPULAR_SECTIONS = 'REQUEST_POPULAR_SECTIONS'
export const UPDATE_POPULAR_SECTIONS = 'UPDATE_POPULAR_SECTIONS'
export const STATUS_OF_POPULAR_SECTIONS = 'STATUS_OF_POPULAR_SECTIONS';

export const REQUEST_HOT_POSTS = 'REQUEST_HOT_POSTS';
export const UPDATE_HOT_POSTS = 'UPDATE_HOT_POSTS';
export const STATUS_OF_HOT_POSTS = 'STATUS_OF_HOT_POSTS';

export const REQUEST_HOT_WORDS = 'REQUEST_HOT_WORDS';
export const UPDATE_HOT_WORDS = 'UPDATE_HOT_WORDS';
export const STATUS_OF_HOT_WORDS = 'STATUS_OF_HOT_WORDS';

export const REQUEST_SENTIMENT = 'REQUEST_SENTIMENT';
export const UPDATE_SENTIMENT_NEWS = 'UPDATE_SENTIMENT_NEWS';
export const UPDATE_SENTIMENT_NON_NEWS = 'UPDATE_SENTIMENT_NON_NEWS';
export const UPDATE_SENTIMENT_ALL = 'UPDATE_SENTIMENT_ALL';

export const UPDATE_SWEARING = 'UPDATE_SWEARING';
export const REQUEST_SWEARING = 'REQUEST_SWEARING';

export const UPDATE_CONFIDENCE = 'UPDATE_CONFIDENCE';
export const REQUEST_CONFIDENCE = 'REQUEST_CONFIDENCE';



export const UPDATE_CHIEF_EXECUTIVE_CARRIE = 'UPDATE_CHIEF_EXECUTIVE_CARRIE'
export const UPDATE_CHIEF_EXECUTIVE_LEUNG = 'UPDATE_CHIEF_EXECUTIVE_LEUNG'
export const UPDATE_CHIEF_EXECUTIVE_DONALD = 'UPDATE_CHIEF_EXECUTIVE_DONALD'
export const UPDATE_CHIEF_EXECUTIVE_TUNG = 'UPDATE_CHIEF_EXECUTIVE_TUNG'

export const UPDATE_LEGCO_MEMBERS = 'UPDATE_LEGCO_MEMBERS'

export const UPDATE_PRINCIPAL_OFFICIALS = 'UPDATE_PRINCIPAL_OFFICIALS'

export const UPDATE_CHIEF_COURT = 'UPDATE_CHIEF_COURT'

export const UPDATE_GOVERNMENT_BRANCHES_ADMINISTRATION = 'UPDATE_GOVERNMENT_BRANCHES_ADMINISTRATION'
export const UPDATE_GOVERNMENT_BRANCHES_LEGISLATION = 'UPDATE_GOVERNMENT_BRANCHES_LEGISLATION'
export const UPDATE_GOVERNMENT_BRANCHES_JUDICIARY = 'UPDATE_GOVERNMENT_BRANCHES_JUDICIARY'

export const UPDATE_POLITICAL_PARTIES_LOCALIST = 'UPDATE_POLITICAL_PARTIES_LOCALIST'
export const UPDATE_POLITICAL_PARTIES_PAN_DEMOCRATIC = 'UPDATE_POLITICAL_PARTIES_PAN_DEMOCRATIC'
export const UPDATE_POLITICAL_PARTIES_MODERATE = 'UPDATE_POLITICAL_PARTIES_MODERATE'
export const UPDATE_POLITICAL_PARTIES_PRO_ESTABLISHMENT = 'UPDATE_POLITICAL_PARTIES_PRO_ESTABLISHMENT'


export const UPDATE_DISCIPLINED_SERVICES_POLICE = 'UPDATE_DISCIPLINED_SERVICES_POLICE'
export const UPDATE_DISCIPLINED_SERVICES_FIRE = 'UPDATE_DISCIPLINED_SERVICES_FIRE'
export const UPDATE_DISCIPLINED_SERVICES_IMMIGRATION = 'UPDATE_DISCIPLINED_SERVICES_IMMIGRATION'
export const UPDATE_DISCIPLINED_SERVICES_CORRECTIONAL = 'UPDATE_DISCIPLINED_SERVICES_CORRECTIONAL'


export const UPDATE_THE_MEDIA_NEWSPAPER = 'UPDATE_THE_MEDIA_NEWSPAPER'
export const UPDATE_THE_MEDIA_RADIO = 'UPDATE_THE_MEDIA_RADIO'
export const UPDATE_THE_MEDIA_TELEVISION = 'UPDATE_THE_MEDIA_TELEVISION'
export const UPDATE_THE_MEDIA_ONLINE = 'UPDATE_THE_MEDIA_ONLINE'


export const UPDATE_PUBLIC_UNIVERSITIES_HKU = 'UPDATE_PUBLIC_UNIVERSITIES_HKU'
export const UPDATE_PUBLIC_UNIVERSITIES_CUHK = 'UPDATE_PUBLIC_UNIVERSITIES_CUHK'
export const UPDATE_PUBLIC_UNIVERSITIES_HKUST = 'UPDATE_PUBLIC_UNIVERSITIES_HKUST'
export const UPDATE_PUBLIC_UNIVERSITIES_CITYU = 'UPDATE_PUBLIC_UNIVERSITIES_CITYU'
export const UPDATE_PUBLIC_UNIVERSITIES_HKBU = 'UPDATE_PUBLIC_UNIVERSITIES_HKBU'
export const UPDATE_PUBLIC_UNIVERSITIES_POLYU = 'UPDATE_PUBLIC_UNIVERSITIES_POLYU'
export const UPDATE_PUBLIC_UNIVERSITIES_OUHK = 'UPDATE_PUBLIC_UNIVERSITIES_OUHK'
export const UPDATE_PUBLIC_UNIVERSITIES_LU = 'UPDATE_PUBLIC_UNIVERSITIES_LU'
export const UPDATE_PUBLIC_UNIVERSITIES_EDUHK = 'UPDATE_PUBLIC_UNIVERSITIES_EDUHK'
export const UPDATE_PUBLIC_UNIVERSITIES_HKAPA = 'UPDATE_PUBLIC_UNIVERSITIES_HKAPA'


export const UPDATE_MAINLAND_CHINA = 'UPDATE_MAINLAND_CHINA';

export const UPDATE_MAINLANDERS = 'UPDATE_MAINLANDERS';

export const UPDATE_MAINLAND_CITIES_FIRST = 'UPDATE_MAINLAND_CITIES_FIRST';
export const UPDATE_MAINLAND_CITIES_NEW = 'UPDATE_MAINLAND_CITIES_NEW';
export const UPDATE_MAINLAND_CITIES_SECOND = 'UPDATE_MAINLAND_CITIES_SECOND'

export const UPDATE_CENTRAL_GOVERNMENT = 'UPDATE_CENTRAL_GOVERNMENT';


export const UPDATE_POLITICAL_ISSUES_UNIVERSAL = 'UPDATE_POLITICAL_ISSUES_UNIVERSAL';
export const UPDATE_POLITICAL_ISSUES_COUNTRY = 'UPDATE_POLITICAL_ISSUES_COUNTRY';

export const UPDATE_ECONOMIC_ISSUES_GREATER = 'UPDATE_ECONOMIC_ISSUES_GREATER';
export const UPDATE_ECONOMIC_ISSUES_HOUSING = 'UPDATE_ECONOMIC_ISSUES_HOUSING';



export const UPDATE_LIVELIHOOD_ISSUES_MINIMUM = 'UPDATE_LIVELIHOOD_ISSUES_MINIMUM';
export const UPDATE_LIVELIHOOD_ISSUES_MAINLAND = 'UPDATE_LIVELIHOOD_ISSUES_MAINLAND';
export const UPDATE_LIVELIHOOD_ISSUES_ENVIRONMENT = 'UPDATE_LIVELIHOOD_ISSUES_ENVIRONMENT';
export const UPDATE_LIVELIHOOD_ISSUES_INCOME = 'UPDATE_LIVELIHOOD_ISSUES_INCOME';
export const UPDATE_LIVELIHOOD_ISSUES_LGBTO = 'UPDATE_LIVELIHOOD_ISSUES_LGBTO';


export const UPDATE_INTERNATIONAL_COMPANIES_CISCO = 'UPDATE_INTERNATIONAL_COMPANIES_CISCO';
export const UPDATE_INTERNATIONAL_COMPANIES_NIKE = 'UPDATE_INTERNATIONAL_COMPANIES_NIKE';
export const UPDATE_INTERNATIONAL_COMPANIES_INTEL = 'UPDATE_INTERNATIONAL_COMPANIES_INTEL';
export const UPDATE_INTERNATIONAL_COMPANIES_LV = 'UPDATE_INTERNATIONAL_COMPANIES_LV';
export const UPDATE_INTERNATIONAL_COMPANIES_ATT = 'UPDATE_INTERNATIONAL_COMPANIES_ATT';
export const UPDATE_INTERNATIONAL_COMPANIES_MCDONALD = 'UPDATE_INTERNATIONAL_COMPANIES_MCDONALD';
export const UPDATE_INTERNATIONAL_COMPANIES_TOYOTA = 'UPDATE_INTERNATIONAL_COMPANIES_TOYOTA';
export const UPDATE_INTERNATIONAL_COMPANIES_DISNEY = 'UPDATE_INTERNATIONAL_COMPANIES_DISNEY';
export const UPDATE_INTERNATIONAL_COMPANIES_SAMSUNG = 'UPDATE_INTERNATIONAL_COMPANIES_SAMSUNG';
export const UPDATE_INTERNATIONAL_COMPANIES_COCACOLA = 'UPDATE_INTERNATIONAL_COMPANIES_COCACOLA';
export const UPDATE_INTERNATIONAL_COMPANIES_FACEBOOK = 'UPDATE_INTERNATIONAL_COMPANIES_FACEBOOK';
export const UPDATE_INTERNATIONAL_COMPANIES_AMAZON = 'UPDATE_INTERNATIONAL_COMPANIES_AMAZON';
export const UPDATE_INTERNATIONAL_COMPANIES_MICROSOFT = 'UPDATE_INTERNATIONAL_COMPANIES_MICROSOFT';
export const UPDATE_INTERNATIONAL_COMPANIES_GOOGLE = 'UPDATE_INTERNATIONAL_COMPANIES_GOOGLE';
export const UPDATE_INTERNATIONAL_COMPANIES_APPLE = 'UPDATE_INTERNATIONAL_COMPANIES_APPLE';



export const UPDATE_LOCAL_RETAIL_PARKNSHOP = 'UPDATE_LOCAL_RETAIL_PARKNSHOP';
export const UPDATE_LOCAL_RETAIL_WELLCOME = 'UPDATE_LOCAL_RETAIL_WELLCOME';
export const UPDATE_LOCAL_RETAIL_711 = 'UPDATE_LOCAL_RETAIL_711';
export const UPDATE_LOCAL_RETAIL_CIRCLEK = 'UPDATE_LOCAL_RETAIL_CIRCLEK';
export const UPDATE_LOCAL_RETAIL_MANNINGS = 'UPDATE_LOCAL_RETAIL_MANNINGS';
export const UPDATE_LOCAL_RETAIL_WASTSONS = 'UPDATE_LOCAL_RETAIL_WASTSONS';
export const UPDATE_LOCAL_RETAIL_VANGUARD = 'UPDATE_LOCAL_RETAIL_VANGUARD';
export const UPDATE_LOCAL_RETAIL_SOGO = 'UPDATE_LOCAL_RETAIL_SOGO';
export const UPDATE_LOCAL_RETAIL_WING = 'UPDATE_LOCAL_RETAIL_WING';
export const UPDATE_LOCAL_RETAIL_SINCERE = 'UPDATE_LOCAL_RETAIL_SINCERE';
export const UPDATE_LOCAL_RETAIL_SUPER = 'UPDATE_LOCAL_RETAIL_SUPER';
export const UPDATE_LOCAL_RETAIL_MARKS = 'UPDATE_LOCAL_RETAIL_MARKS';
export const UPDATE_LOCAL_RETAIL_YATA = 'UPDATE_LOCAL_RETAIL_YATA';
export const UPDATE_LOCAL_RETAIL_JAPAN = 'UPDATE_LOCAL_RETAIL_JAPAN';
export const UPDATE_LOCAL_RETAIL_DCH = 'UPDATE_LOCAL_RETAIL_DCH';



export const UPDATE_MAINLAND_COMPANIES_STATE = 'UPDATE_MAINLAND_COMPANIES_STATE';
export const UPDATE_MAINLAND_COMPANIES_WELLCOME = 'UPDATE_MAINLAND_COMPANIES_WELLCOME';
export const UPDATE_MAINLAND_COMPANIES_CNPC = 'UPDATE_MAINLAND_COMPANIES_CNPC';
export const UPDATE_MAINLAND_COMPANIES_ICBC = 'UPDATE_MAINLAND_COMPANIES_ICBC';
export const UPDATE_MAINLAND_COMPANIES_CSCEC = 'UPDATE_MAINLAND_COMPANIES_CSCEC';
export const UPDATE_MAINLAND_COMPANIES_PINGAN = 'UPDATE_MAINLAND_COMPANIES_PINGAN';
export const UPDATE_MAINLAND_COMPANIES_CCB = 'UPDATE_MAINLAND_COMPANIES_CCB';
export const UPDATE_MAINLAND_COMPANIES_SAICMOTOR = 'UPDATE_MAINLAND_COMPANIES_SAICMOTOR';
export const UPDATE_MAINLAND_COMPANIES_ABC = 'UPDATE_MAINLAND_COMPANIES_ABC';
export const UPDATE_MAINLAND_COMPANIES_LIFE = 'UPDATE_MAINLAND_COMPANIES_LIFE';
export const UPDATE_MAINLAND_COMPANIES_BOC = 'UPDATE_MAINLAND_COMPANIES_BOC';
export const UPDATE_MAINLAND_COMPANIES_MOBILE = 'UPDATE_MAINLAND_COMPANIES_MOBILE';
export const UPDATE_MAINLAND_COMPANIES_DFMC = 'UPDATE_MAINLAND_COMPANIES_DFMC';
export const UPDATE_MAINLAND_COMPANIES_HUAWEI = 'UPDATE_MAINLAND_COMPANIES_HUAWEI';
export const UPDATE_MAINLAND_COMPANIES_SUNING = 'UPDATE_MAINLAND_COMPANIES_SUNING';


export const REQUEST_DATA_SHRAING = 'REQUEST_DATA_SHRAING';
export const UPDATE_DATA_SHARING = 'UPDATE_DATA_SHARING';















