import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    PARKnSHOP: {
        'mentions': [],
        'analysis': []
    },
    Wellcome: {
        'mentions': [],
        'analysis': []
    },
    711: {
        'mentions': [],
        'analysis': []
    },
    circleK: {
        'mentions': [],
        'analysis': []
    },
    Mannings: {
        'mentions': [],
        'analysis': []
    },
    Watsons: {
        'mentions': [],
        'analysis': []
    },
    Vanguard: {
        'mentions': [],
        'analysis': []
    },
    Sogo: {
        'mentions': [],
        'analysis': []
    },
    Wing: {
        'mentions': [],
        'analysis': []
    },
    Sincere: {
        'mentions': [],
        'analysis': []
    },
    super: {
        'mentions': [],
        'analysis': []
    },
    Marks: {
        'mentions': [],
        'analysis': []
    },
    YATA: {
        'mentions': [],
        'analysis': []
    },
    Japan: {
        'mentions': [],
        'analysis': []
    },
    DCH: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_LOCAL_RETAIL_PARKNSHOP: {
            let init = state.get('PARKnSHOP');
            init[action.name] = action.data;
            return state
                .set('PARKnSHOP', init)
        }


        case types.UPDATE_LOCAL_RETAIL_WELLCOME: {
            let init = state.get('Wellcome');
            init[action.name] = action.data;
            return state
                .set('Wellcome', init)
        }


        case types.UPDATE_LOCAL_RETAIL_711: {
            let init = state.get('711');
            init[action.name] = action.data;
            return state
                .set('711', init)
        }


        case types.UPDATE_LOCAL_RETAIL_CIRCLEK: {
            let init = state.get('circleK');
            init[action.name] = action.data;
            return state
                .set('circleK', init)
        }

        case types.UPDATE_LOCAL_RETAIL_MANNINGS: {
            let init = state.get('Mannings');
            init[action.name] = action.data;
            return state
                .set('Mannings', init)
        }


        case types.UPDATE_LOCAL_RETAIL_WASTSONS: {
            let init = state.get('Watsons');
            init[action.name] = action.data;
            return state
                .set('Watsons', init)
        }


        case types.UPDATE_LOCAL_RETAIL_VANGUARD: {
            let init = state.get('Vanguard');
            init[action.name] = action.data;
            return state
                .set('Vanguard', init)
        }


        case types.UPDATE_LOCAL_RETAIL_SOGO: {
            let init = state.get('Sogo');
            init[action.name] = action.data;
            return state
                .set('Sogo', init)
        }

        case types.UPDATE_LOCAL_RETAIL_WING: {
            let init = state.get('Wing');
            init[action.name] = action.data;
            return state
                .set('Wing', init)
        }


        case types.UPDATE_LOCAL_RETAIL_SINCERE: {
            let init = state.get('Sincere');
            init[action.name] = action.data;
            return state
                .set('Sincere', init)
        }


        case types.UPDATE_LOCAL_RETAIL_SUPER: {
            let init = state.get('super');
            init[action.name] = action.data;
            return state
                .set('super', init)
        }


        case types.UPDATE_LOCAL_RETAIL_MARKS: {
            let init = state.get('Marks');
            init[action.name] = action.data;
            return state
                .set('Marks', init)
        }

        case types.UPDATE_LOCAL_RETAIL_YATA: {
            let init = state.get('YATA');
            init[action.name] = action.data;
            return state
                .set('YATA', init)
        }


        case types.UPDATE_LOCAL_RETAIL_JAPAN: {
            let init = state.get('Japan');
            init[action.name] = action.data;
            return state
                .set('Japan', init)
        }


        case types.UPDATE_LOCAL_RETAIL_DCH: {
            let init = state.get('DCH');
            init[action.name] = action.data;
            return state
                .set('DCH', init)
        }



        default:
            return state;
    }
}