import immutablePersistenceTransform from '../helpers/immutablePersistenceTransform'
import storage from 'redux-persist/lib/storage';
import { whitelist } from "../reducers";

const REDUX_PERSIST = {
    reducerVersion: '1.1',
    storeConfig: {
        key: 'root',
        storage: storage,
        whitelist: whitelist,
        transforms: [immutablePersistenceTransform],
    }
};

export default REDUX_PERSIST