import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    police: {
        'mentions': [],
        'analysis': []
    },
    fire: {
        'mentions': [],
        'analysis': []
    },
    immigration: {
        'mentions': [],
        'analysis': []
    },
    correctional: {
        'mentions': [],
        'analysis': []
    },
});

export default function HKReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_DISCIPLINED_SERVICES_POLICE: {
            let init = state.get('police');
            init[action.name] = action.data;
            return state
                .set('police', init)
        }


        case types.UPDATE_DISCIPLINED_SERVICES_FIRE: {
            let init = state.get('fire');
            init[action.name] = action.data;
            return state
                .set('fire', init)
        }


        case types.UPDATE_DISCIPLINED_SERVICES_IMMIGRATION: {
            let init = state.get('immigration');
            init[action.name] = action.data;
            return state
                .set('immigration', init)
        }
        case types.UPDATE_DISCIPLINED_SERVICES_CORRECTIONAL: {
            let init = state.get('correctional');
            init[action.name] = action.data;
            return state
                .set('correctional', init)
        }



        default:
            return state;
    }
}