import { List } from 'immutable';
import axios from 'axios/index';
import config from '../config';
import { store, history } from '../store/store';

const skipAuth = List([
    '/account/login',
    '/account/register',
]);
let axiosInstance = axios.create({
    baseURL: config.apiUrl,
    timeout: 100000,
    method:  'post',
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*"
    },
    responseType: "json",
});



//request interceptors-请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        if(skipAuth.includes(config.url)) {
            return config;
        }
        const auth = store.getState().Auth;
        config.headers.common['x-session-token'] = auth.get('token');
        return config;
    },
    err => {
        return Promise.reject(err)
    });


//response interceptors-相应拦截器
axiosInstance.interceptors.response.use(
    response => {
        return response.data;
        // 在这里你可以判断后台返回数据携带的请求码
        if (response.status === 200) {
            return response.data;
        } else {

        }
    },
    err => {
        const message = err.message;

        if (message.indexOf(401) > -1) {
            history.push('/401');
        }
        if (message.indexOf(403) > -1) {
            history.push('/403');
        }
        if (message.indexOf(500) > -1) {
            history.push('/500');
        }
        if (message.indexOf(502) > -1) {
            history.push('/502');
        }
        if (message.indexOf(404) > -1) {
            history.push('/404');
        }

        return Promise.reject(err);
    });

function api(service, body) {
    return new Promise((resolve, reject) => {
        axiosInstance.post(service, body).then(response => {

            if(response.error_code===0){
                resolve(response.data);
            }
            else {
                resolve({
                    error: response.error_msg + '[' + response.error_code + ']'
                });
            }
        }).catch((err) => {
            if(err && err.response && err.response.data){
                resolve({
                    error: err.response.data.error_msg + '[' + err.response.data.error_code + ']'
                });
            }
            resolve({error: '请求错误[-1]'});
        });
    });
}

export function request(service, body) {
    return new Promise((resolve, reject) => {
        axiosInstance.post(service, body).then(response => {
        console.log(response,'response')
            if(response.error_code===0){
                resolve(response.data);
            }
            else {
                reject({
                    error_code: response.error_code,
                    error_msg: response.error_msg,
                })
            }
        }).catch((err) => {
            reject({
                error_code: err && err.error_code ? err.error_code : '-1',
                error_msg: err && err.error_msg ? err.error_msg : '未知错误',
            });
        });
    });
}

export default api;

export function upload({action, data, file, filename, onProgress, onSuccess, onError, withCredentials}) {
    const formData = new FormData();
    if (data) {
        Object.keys(data).map(key => {
            formData.append(key, data[key]);
        });
    }
    formData.append(filename, file);

    const auth = store.getState().Auth;
    let idToken = auth.get('token');
    const headers = {
        'x-session-token': idToken
    };

    axios.post(action, formData, {
        headers,
        onUploadProgress: ({ total, loaded }) => {
            onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) } , file);
        },
    }).then(({ data: response }) => {
        if(response.error_code === 0) {
            onSuccess(response.data, file);
        }
        else {
            onError({error: response.error_msg + '[' + response.error_code + ']'})
        }
    }).catch(err => onError(err, file));
}