import React from 'react';
import { Provider } from 'react-redux';
import { store, history, persistor } from './store/store';
import PublicRoutes from './router';
import { ThemeProvider } from 'styled-components';
import themes from './config/themes';
import { themeConfig } from './config';
import RootHolder from './rootHolderStyle';
import Boot from './store/boot';
import {hot} from "react-hot-loader";
import { PersistGate } from 'redux-persist/es/integration/react';
import Restore from './components/utility/restore';

const RootApp = () => (

      <ThemeProvider theme={themes[themeConfig.theme]}>
        <RootHolder>
          <Provider store={store}>
              <PersistGate
                  loading={<Restore />}
                  //onBeforeLift={onBeforeLift}
                  persistor={persistor}>
                    <PublicRoutes history={history} />
              </PersistGate>
          </Provider>
        </RootHolder>
      </ThemeProvider>
);

Boot()
  .then(() => {return process.env.NODE_ENV === "development" ? hot(module)(RootApp()) : RootApp()})
  .catch(error => console.error(error));

export default RootApp;
