import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from '../pages/app/app';

class AppContainer extends React.Component {

    constructor(props) {
        super(props);
    }
    // componentDidMount(){
    //     window.addEventListener('resize', this.resizeWindow);
    
    //   }
    //   componentWillUnmount() {       
    //     window.removeEventListener('resize',this.resizeWindow);
    //   }
    //   resizeWindow =()=>{
    
    //     let designSize = 1920; // 设计图尺寸
      
    //     let html = document.documentElement;
      
    //     let wW = html.clientWidth;// 窗口宽度
    //     let rem = wW * 100 / designSize; 
    //     document.documentElement.style.fontSize = rem + 'px';
    //   }


    render() {
        return <App {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
