import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import AppContainer from './containers/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={props => isLoggedIn
            ? <Component {...props} />
            : <Redirect
                to={{
                    pathname: '/signin',
                    state: { from: props.location },
                }}
            />}
    />
);

const PublicRoutes = ({ history, isLoggedIn }) => {

   
    return (
        <ConnectedRouter history={history}>
            <div>
                {/* <Route
                    exact
                    path={'/'}
                    component={AppContainer}
                /> */}
                <RestrictedRoute
                    path="/"
                    component={AppContainer}
                    isLoggedIn={isLoggedIn}
                />
            </div>
        </ConnectedRouter>
    );
};

export default connect(state => {
    return {
        isLoggedIn: true//!(state.Auth.get('token') === null),
    }
})(PublicRoutes);
