import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`  
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.contentMainLayout {
      @media only screen and (max-width: 767px) {
        width: 100%;

      }
    }
  }
  
  .layoutContent {
  // overflow:hidden;
    width: 100%;
    background-color: #ffffff;
    min-height: auto;
  }

  .ant-layout-footer {
    font-size: 13px;
    padding: 15px 50px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }
`;

export default AppHolder;
