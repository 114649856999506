import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    HKU: {
        'mentions': [],
        'analysis': []
    },
    CUHK: {
        'mentions': [],
        'analysis': []
    },
    HKUST: {
        'mentions': [],
        'analysis': []
    },
    CityU: {
        'mentions': [],
        'analysis': []
    },
    HKBU: {
        'mentions': [],
        'analysis': []
    },
    PolyU: {
        'mentions': [],
        'analysis': []
    },
    OUHK: {
        'mentions': [],
        'analysis': []
    },
    LU: {
        'mentions': [],
        'analysis': []
    },
    EdUHK: {
        'mentions': [],
        'analysis': []
    },
    HKAPA: {
        'mentions': [],
        'analysis': []
    },
});

export default function HKReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_PUBLIC_UNIVERSITIES_HKU: {
            let init = state.get('HKU');
            init[action.name] = action.data;
            return state
                .set('HKU', init)
        }


        case types.UPDATE_PUBLIC_UNIVERSITIES_CUHK: {
            let init = state.get('CUHK');
            init[action.name] = action.data;
            return state
                .set('CUHK', init)
        }


        case types.UPDATE_PUBLIC_UNIVERSITIES_HKUST: {
            let init = state.get('HKUST');
            init[action.name] = action.data;
            return state
                .set('HKUST', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_CITYU: {
            let init = state.get('CityU');
            init[action.name] = action.data;
            return state
                .set('CityU', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_HKBU: {
            let init = state.get('HKBU');
            init[action.name] = action.data;
            return state
                .set('HKBU', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_POLYU: {
            let init = state.get('PolyU');
            init[action.name] = action.data;
            return state
                .set('PolyU', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_OUHK: {
            let init = state.get('OUHK');
            init[action.name] = action.data;
            return state
                .set('OUHK', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_LU: {
            let init = state.get('LU');
            init[action.name] = action.data;
            return state
                .set('LU', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_EDUHK: {
            let init = state.get('EdUHK');
            init[action.name] = action.data;
            return state
                .set('EdUHK', init)
        }
        case types.UPDATE_PUBLIC_UNIVERSITIES_HKAPA: {
            let init = state.get('HKAPA');
            init[action.name] = action.data;
            return state
                .set('HKAPA', init)
        }




        default:
            return state;
    }
}