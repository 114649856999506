import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    administration: {
        'mentions': [],
        'analysis': []
    },
    legislation: {
        'mentions': [],
        'analysis': []
    },
    judiciary: {
        'mentions': [],
        'analysis': []
    },

});

export default function governmentBranchesReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_GOVERNMENT_BRANCHES_ADMINISTRATION: {
            let init = state.get('administration');
            init[action.name] = action.data;
            return state
                .set('administration', init)
        }


        case types.UPDATE_GOVERNMENT_BRANCHES_LEGISLATION: {
            let init = state.get('legislation');
            init[action.name] = action.data;
            return state
                .set('legislation', init)
        }


        case types.UPDATE_GOVERNMENT_BRANCHES_JUDICIARY: {
            let init = state.get('judiciary');
            init[action.name] = action.data;
            return state
                .set('judiciary', init)
        }



        default:
            return state;
    }
}