import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';
import { asyncConfig } from '../config'

const initState = Map({
    willSyncItems: asyncConfig.sync.length,
    step: 0,
    currentSyncItem: null,
    flushedActions: null,
    message: '准备初始化数据',
    isInitializing: null,
    error: {code: 0, message: ''},

    willRefresh: true,
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.SYNC_START:
            return state.set('isInitializing', false).set('message', '更新系统数据中');

        case types.SYNC_FLUSH:
            return state.set('flushedActions', action.actions);

        case types.SYNC_STEP:
            return state
                .set('step', state.get('step') + 1)
                .set('currentSyncItem', action.payload);

        case types.ASYNC_FINISHED:
            return initState.set('message',  '数据更新完成，准备进入控制台');

        case types.SYNC_ERROR:
            return state
                .set('error', action.error)
                .set('isInitializing', false);

        case types.SYNC_REFRESH:
            return state
                .set('willRefresh', !state.get('willRefresh'));

        default:
            return state;
    }
}