import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

export default class extends React.Component {

    render() {
        return (
            <Switch>
                {/* 首页 */}
                <Route
                    exact
                    path={'/'}
                    component={asyncComponent(() => import('../../containers/Home'))}
                />
                <Route
                    exact
                    path={'/home/aboutUs'}
                    component={asyncComponent(() => import('../../containers/home/AboutUs'))}
                />
                <Route
                    exact
                    path={'/home/researchReport'}
                    component={asyncComponent(() => import('../../containers/home/ResearchReport'))}
                />
                <Route
                    exact
                    path={'/home/researchMethodology'}
                    component={asyncComponent(() => import('../../containers/home/ResearchMethodology'))}
                />
                <Route
                    exact
                    path={'/home/dataSharing'}
                    component={asyncComponent(() => import('../../containers/home/DataSharing'))}
                />



                {/* 今日热点 */}
                <Route
                    exact
                    path={'/talkOfTheTown'}
                    component={asyncComponent(() => import('../../containers/TalkOfTheTwon'))}
                />

                {/* 民意趋势 */}
                <Route
                    exact
                    path={'/publicOpinion/activeLevel'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/ActiveLevel'))}
                />

                <Route
                    exact
                    path={'/publicOpinion/hotPosts'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/HotPosts'))}
                />

                <Route
                    exact
                    path={'/publicOpinion/hotWords'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/HotWords'))}
                />
                <Route
                    exact
                    path={'/publicOpinion/popularSections'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/PopularSections'))}
                />

                <Route
                    exact
                    path={'/publicOpinion/sentiment'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/Sentiment'))}
                />
                <Route
                    exact
                    path={'/publicOpinion/swearing/analysis'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/swearing/Analysis'))}
                />
                <Route
                    exact
                    path={'/publicOpinion/swearing/mentions'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/swearing/Mentions'))}
                />
                <Route
                    exact
                    path={'/publicOpinion/comsumerConfidence'}
                    component={asyncComponent(() => import('../../containers/publicOpinion/ComsumerConfidence'))}
                />

                {/* 公众人物民望 */}

                <Route
                    exact
                    path={'/publicFigurePopularity/principalOfficials/analysis'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/principalOfficials/Analysis'))}
                />
                <Route
                    exact
                    path={'/publicFigurePopularity/principalOfficials/mentions'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/principalOfficials/Mentions'))}
                />

                <Route
                    exact
                    path={'/publicFigurePopularity/chiefExecutive/analysis'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/chiefExecutive/Analysis'))}
                />
                <Route
                    exact
                    path={'/publicFigurePopularity/chiefExecutive/mentions'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/chiefExecutive/Mentions'))}
                />

                <Route
                    exact
                    path={'/publicFigurePopularity/legcoMembers/analysis'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/legcoMembers/Analysis'))}
                />
                <Route
                    exact
                    path={'/publicFigurePopularity/legcoMembers/mentions'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/legcoMembers/Mentions'))}
                />

                <Route
                    exact
                    path={'/publicFigurePopularity/chiefCourt/analysis'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/chiefCourt/Analysis'))}
                />
                <Route
                    exact
                    path={'/publicFigurePopularity/chiefCourt/mentions'}
                    component={asyncComponent(() => import('../../containers/publicFigurePopularity/chiefCourt/Mentions'))}
                />


                {/* 機構團體民望 */}
                <Route
                    exact
                    path={'/organizationPopularity/disciplinedServices/analysis'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/disciplinedServices/Analysis'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/disciplinedServices/mentions'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/disciplinedServices/Mentions'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/governmentBranches/analysis'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/governmentBranches/Analysis'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/governmentBranches/mentions'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/governmentBranches/Mentions'))}
                />

                <Route
                    exact
                    path={'/organizationPopularity/politicalParties/analysis'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/politicalParties/Analysis'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/politicalParties/mentions'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/politicalParties/Mentions'))}
                />

                <Route
                    exact
                    path={'/organizationPopularity/publicUniversities/analysis'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/publicUniversities/Analysis'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/publicUniversities/mentions'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/publicUniversities/Mentions'))}
                />

                <Route
                    exact
                    path={'/organizationPopularity/theMedia/analysis'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/theMedia/Analysis'))}
                />
                <Route
                    exact
                    path={'/organizationPopularity/theMedia/mentions'}
                    component={asyncComponent(() => import('../../containers/organizationPopularity/theMedia/Mentions'))}
                />






                <Route
                    exact
                    path={'/mainland/mainlandChina/analysis'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlandChina/Analysis'))}
                />
                <Route
                    exact
                    path={'/mainland/mainlandChina/mentions'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlandChina/Mentions'))}
                />
                <Route
                    exact
                    path={'/mainland/centralGovernment/analysis'}
                    component={asyncComponent(() => import('../../containers/mainland/centralGovernment/Analysis'))}
                />
                <Route
                    exact
                    path={'/mainland/centralGovernment/mentions'}
                    component={asyncComponent(() => import('../../containers/mainland/centralGovernment/Mentions'))}
                />
                <Route
                    exact
                    path={'/mainland/mainlandCities/analysis'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlandCities/Analysis'))}
                />
                <Route
                    exact
                    path={'/mainland/mainlandCities/mentions'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlandCities/Mentions'))}
                />
                <Route
                    exact
                    path={'/mainland/mainlanders/analysis'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlanders/Analysis'))}
                />
                <Route
                    exact
                    path={'/mainland/mainlanders/mentions'}
                    component={asyncComponent(() => import('../../containers/mainland/mainlanders/Mentions'))}
                />





                <Route
                    exact
                    path={'/socialIssues/economic/analysis'}
                    component={asyncComponent(() => import('../../containers/socialIssues/economic/Analysis'))}
                />
                <Route
                    exact
                    path={'/socialIssues/economic/mentions'}
                    component={asyncComponent(() => import('../../containers/socialIssues/economic/Mentions'))}
                />
                <Route
                    exact
                    path={'/socialIssues/livelihood/analysis'}
                    component={asyncComponent(() => import('../../containers/socialIssues/livelihood/Analysis'))}
                />
                <Route
                    exact
                    path={'/socialIssues/livelihood/mentions'}
                    component={asyncComponent(() => import('../../containers/socialIssues/livelihood/Mentions'))}
                />
                <Route
                    exact
                    path={'/socialIssues/political/analysis'}
                    component={asyncComponent(() => import('../../containers/socialIssues/political/Analysis'))}
                />
                <Route
                    exact
                    path={'/socialIssues/political/mentions'}
                    component={asyncComponent(() => import('../../containers/socialIssues/political/Mentions'))}
                />







                <Route
                    exact
                    path={'/corporate/internationalCompanie/analysis'}
                    component={asyncComponent(() => import('../../containers/corporate/internationalCompanie/Analysis'))}
                />
                <Route
                    exact
                    path={'/corporate/internationalCompanie/mentions'}
                    component={asyncComponent(() => import('../../containers/corporate/internationalCompanie/Mentions'))}
                />

                <Route
                    exact
                    path={'/corporate/localRetail/analysis'}
                    component={asyncComponent(() => import('../../containers/corporate/localRetail/Analysis'))}
                />
                <Route
                    exact
                    path={'/corporate/localRetail/mentions'}
                    component={asyncComponent(() => import('../../containers/corporate/localRetail/Mentions'))}
                />


                <Route
                    exact
                    path={'/corporate/mainlandCompanies/analysis'}
                    component={asyncComponent(() => import('../../containers/corporate/mainlandCompanies/Analysis'))}
                />
                <Route
                    exact
                    path={'/corporate/mainlandCompanies/mentions'}
                    component={asyncComponent(() => import('../../containers/corporate/mainlandCompanies/Mentions'))}
                />


            </Switch>
        );
    }
}
