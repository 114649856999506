import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    records:[]
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.UPDATE_HOT_POSTS:
          return  state
                    .set('records', action.data)
       
        default:
            return state;
    }
}