import {objToArr, toTreeData, isNull} from '../utils/utility';

export class Table {
    isEmpty = false;
    data = {};

    //id与key的对应关系
    ids = {};

    //key_code与id的对应关系
    keys = {};

    constructor(table) {
        if(isNull(table)) {
            this.isEmpty = true;
        }
        else {
            table && Object.keys(table).map(name => {
                const confs =  table[name];

                Object.keys(confs).map(id => {
                    const conf = confs[id];
                    //----------------------------------confs-------------------------------------
                    //--------------------------********* conf *******----********** conf *********
                    //-----------name-----------index----id---code--name--index---id---code----name
                    //      pollution_source	[ 0:  {100001  air  废气},	1:  {100002  water  废水}]
                    this.keys[`${name}_${conf.code}`] = id;
                    this.ids[conf.id] = name;
                });
            });
            this.data = table ? table : {};
        }
    }

    createCollection = (key) => {
        const data = this.getData(key);
        return new Collection(data);
    };

    getData = (key) => {
        if(!key) {
            key = Object.keys(this.data)[0];
        }
        return this.data[key] ? this.data[key] : {};
    };

    getOption = (key, cascader=1) => {
        let option = objToArr(this.getData(key));

        let level = 1;
        if(cascader > level) {
            let subOption = [];
            option.map((v1, k1) => {
                subOption =  objToArr(this.getData(v1.code));

                if(cascader > (level + 1)) {
                    subOption.map((v2, k2) => {
                        const subChildren = objToArr(this.getData(v2.code));
                        if(subChildren.length > 0) {
                            subOption[k2]['children'] = subChildren;
                        }
                    });
                }

                if(subOption.length > 0) {
                    option[k1]['children'] = subOption;
                }
            });
        }
        return option;
    };

    getSubOption = (id) => {
        const key = this.code(id);
        return this.getOption(key);
    };

    get = (key) => {
        let conf = {};
        let id = key;
        if( !/^[0-9]+$/.test(key) ) {
            id = this.id(key);
            if(id === undefined) {
                return {};
            }
        }
        const tableName = this.ids[id];

        if(tableName) {
            const confs = this.data[tableName];
            conf = confs && confs[id] ? confs[id] : {}
        }

        return conf;
    };


    name = (key, func) => {
        if(!key) return null;
        if(typeof key === 'string'){
            const item = this.get(key);
            return func ? func(item) : item.name ?  item.name : null;
        }else if(key instanceof Array){
             
        let arr = key.map((item)=>{
                let handle = this.get(item);
                return handle.name ?  handle.name : null
            })
            return  arr?arr:null;
        }
       
       
    };

    code = (key, func) => {
        if(!/^[0-9]+$/.test(key)) {
            return null;
        }
        const item = this.get(key);
        return func ? func(item) : item.code ?  item.code : null;
    };

    //from `name_code` get `id`
    id = (assembledKey) => {
        return this.keys[assembledKey];
    };

    isMatch = (id, key) => {
        return key === this.code(id);
    };

    toObject = () => this.data;
}

export class Attribute {
    data = {};
    //id=>attr
    collection = null;
    //code与id的对应关系
    codes = {};
    //id与code的对应关系
    ids = {};

    constructor(attribute, collection) {
        this.collection = collection;
        Object.keys(collection).map(key => {
            this.codes[collection[key]['code']] =  key;
            this.ids[key] = collection[key]['code'];
        });
        attribute.map(attr => {
            //把数组转化成id->attr的映射关系对象
            this.data[attr.attribute_id] =  attr;
        });
    }

    getData = () => {
        return this.data;
    };

    getOption = () => {
        objToArr(this.getData());
    };

    get = (key) => {
        let id = key;
        if(!/^[0-9]+$/.test(id)) {
            id = this.codes[key];
        }
        return  this.data[id];
    };

    getValue = (key) => {
        const value= this.get(key);
        return value ? value['attribute_value'] : undefined;
    };

    valueMatch = (key, func) => {
        let isMatch = false;
        const value = this.getValue(key);
        if(value) {
            if(value instanceof Array) {
                isMatch = value.find(v => func(v)) !== undefined;
            }
            else {
                isMatch = func(value);
            }
        }
        return isMatch;
    };

    attr = (key, value, type) => {
        let id = key;
        if(!/^[0-9]+$/.test(id)) {
            id = this.codes[key];
        }

        this.data[id] = {
            attribute_id: id,
            attribute_value: value,
            type: type ? type : ''
        };
        return this;
    };

    toArray = () => {
        return objToArr(this.data);
    }
}


//use as option in Select like Component
//it can got selected id to select object conveniently
export class Collection {
    isEmpty = false;
    data = [];
    ids = {};

    constructor(data) {
        if(isNull(data)) {
            this.isEmpty = true;
        }
        else {
            this.isEmpty = false;
            Object.keys(data).map(k => {  console.log();
                this.data.push(data[k]);
                this.ids[data[k]['id']] = this.data.length - 1;
            });
        }
    }

    //拆分collection的值重新创建一个新的collection
    part = (func) => {
        const data = this.data.filter(v => func(v));
        return new Collection(data)
    };

    getData = (ids) => {
        let data = [];

        if(typeof ids === 'string') {
            data = this.ids[ids] !== undefined ? this.data[this.ids[ids]] ? this.data[this.ids[ids]] : {} : {};
        }

        if(ids instanceof Array) {
            data = this.data.filter(v => ids.indexOf(v.id) > -1);
        }
        return data;
    };

    getOption = (ids) => {
        let data = this.data;

        if(ids instanceof Array) {
            data = this.data.filter(v => ids.indexOf(v.id) === -1);
        }
        return data;
    };

    //{rootId, label, value}
    getCascader = (option={}) => {
        const data = [...this.data];
        return toTreeData(data, {
            rootId: '0',
            children: 'children',
            pid: 'pid',
            id: 'id',
            label: 'name',
            value: 'id',
            ...option
        });
    };
}