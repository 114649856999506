import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    minimum: {
        'mentions': [],
        'analysis': []
    },
    mainland : {
        'mentions': [],
        'analysis': []
    },
    environment: {
        'mentions': [],
        'analysis': []
    },
    income: {
        'mentions': [],
        'analysis': []
    },
    LGBTQ: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_LIVELIHOOD_ISSUES_MINIMUM: {
            let init = state.get('minimum');
            init[action.name] = action.data;
            return state
                .set('minimum', init)
        }


        case types.UPDATE_LIVELIHOOD_ISSUES_MAINLAND: {
            let init = state.get('mainland');
            init[action.name] = action.data;
            return state
                .set('mainland', init)
        }


        case types.UPDATE_LIVELIHOOD_ISSUES_ENVIRONMENT: {
            let init = state.get('environment');
            init[action.name] = action.data;
            return state
                .set('environment', init)
        }


        case types.UPDATE_LIVELIHOOD_ISSUES_INCOME: {
            let init = state.get('income');
            init[action.name] = action.data;
            return state
                .set('income', init)
        }

        case types.UPDATE_LIVELIHOOD_ISSUES_LGBTO: {
            let init = state.get('LGBTQ');
            init[action.name] = action.data;
            return state
                .set('LGBTQ', init)
        }


        default:
            return state;
    }
}