import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    words:[],
    sentiment:[]
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.UPDATE_HOT_WORDS:
          return  state
          .set('words', action.data.words)
          .set('sentiment', action.data.sentiment)
       
        default:
            return state;
    }
}