import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';
const initState = Map({
    mentions: [],
    analysis: []
});

export default function taskReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_SWEARING: {
            return state
                .set('mentions', action.data.mentions)
                .set('analysis', action.data.analysis)
        }
        default:
            return state;
    }
}