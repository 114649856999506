import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Topbar from '../pages/topBar/topbar';


class TopBarContainer extends React.Component {
    render() {
        return <Topbar {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.App,
        auth: state.Auth,
    };
};

const mapDispatchToProps = (dispatch) => {

    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
