import * as types from "./constants/ActionTypes";
const devServer = '192.168.2.10:18018';
const pubServer = 'api.temmi.cn';
const schema = { dev: { http: 'http://', ws: 'ws://' }, pub: { http: 'https://', ws: 'wss://' } };

export default {
  // apiUrl: process.env.NODE_ENV === 'development' ? schema.dev.http + devServer : schema.pub.http + pubServer + '/envirkeeper',
  apiUrl: 'http://144.214.150.92:5000',
  webSocketUrl: process.env.NODE_ENV === 'development' ? schema.dev.ws + devServer : schema.pub.ws + pubServer + '/websocket',
  uploadUrl: "http://upload.temmi.cn:10021",
  imgPrefix: "http://resource.temmi.cn:8888",
  namespace: '/envirkeeper',
  loginUrl: "https://www.temmi.cn/account/signin.html",
}

const siteConfig = {
  siteName: '环保e管家',
  siteNameTex: '环保e管家',
  siteDescribe: '依托互联网和物联网技术帮助企业轻松应对环保事务',
  siteIcon: 'ion-flash',
  footerText: 'Temmi ©2019 Created by Temmi, Inc',
  robot: 'umma'
};

const systemConfig = {
  useReactotron: true,
  pageSize: 15,
  tries: 30,
  amapKey: '5e58bbf5fdf8ffffeb7a50a2df6ad623',
  amapVersion: '1.4.10',
  indexedDb: 'ENVIRONMENT_INDEXED_DATABASE',
  asyncConfig: [
    'group',
    'department',
    'staff',
    'product',
    'control',
    'monitoring',
    ''
  ]
};

const asyncConfig = {
  sync: [
    // {name: '配置表', type: 'store', url: '/talkofthetown', action: types.UPDATE_CONFIGURATION_TABLE},
    // {name: '生产设施', type: 'store', url: '/publicfigurepopularity/activitylevel', action: types.UPDATE_PRODUCE_FACILITIES},

  ],
  async: []
};



const optionsConfig = {
  'task2': [
    {
      task: 'Task2', data: [
        { category: 'Carrie Lam', action: types.UPDATE_CHIEF_EXECUTIVE_CARRIE },
        { category: 'Leung Chun-ying', action: types.UPDATE_CHIEF_EXECUTIVE_LEUNG },
        { category: 'Donald Tsang', action: types.UPDATE_CHIEF_EXECUTIVE_DONALD },
        { category: 'Tung Chee-hwa', action: types.UPDATE_CHIEF_EXECUTIVE_TUNG },],
    },

    { task: 'Task2', data: [{ category: 'LegCo members', action: types.UPDATE_LEGCO_MEMBERS }], },
    { task: 'Task2', data: [{ category: 'Principal officials', action: types.UPDATE_PRINCIPAL_OFFICIALS }], },
    { task: 'Task2', data: [{ category: 'Chief of the court of final appeal', action: types.UPDATE_CHIEF_COURT }], },
  ],
  'task3': [
    {
      task: 'Task3', data: [
        { category: 'Administration', action: types.UPDATE_GOVERNMENT_BRANCHES_ADMINISTRATION },
        { category: 'Legislation', action: types.UPDATE_GOVERNMENT_BRANCHES_LEGISLATION },
        { category: 'Judiciary', action: types.UPDATE_GOVERNMENT_BRANCHES_JUDICIARY },
      ]
    },
    {
      task: 'Task3', data: [
        { category: 'Localist', action: types.UPDATE_POLITICAL_PARTIES_LOCALIST },
        { category: 'Pan-democratic', action: types.UPDATE_POLITICAL_PARTIES_PAN_DEMOCRATIC },
        { category: 'Moderate', action: types.UPDATE_POLITICAL_PARTIES_MODERATE },
        { category: 'Pro-establishment', action: types.UPDATE_POLITICAL_PARTIES_PRO_ESTABLISHMENT },
      ]
    },
    {
      task: 'Task3', data: [
        { category: 'Police', action: types.UPDATE_DISCIPLINED_SERVICES_POLICE },
        { category: 'Fire services', action: types.UPDATE_DISCIPLINED_SERVICES_FIRE },
        { category: 'Immigration', action: types.UPDATE_DISCIPLINED_SERVICES_IMMIGRATION },
        { category: 'Correctional services', action: types.UPDATE_DISCIPLINED_SERVICES_CORRECTIONAL },
      ]
    },
    {
      task: 'Task3', data: [
        { category: 'Newspaper', action: types.UPDATE_THE_MEDIA_NEWSPAPER },
        { category: 'Radio', action: types.UPDATE_THE_MEDIA_RADIO },
        { category: 'Television', action: types.UPDATE_THE_MEDIA_TELEVISION },
        { category: 'Online independent media', action: types.UPDATE_THE_MEDIA_ONLINE },
      ]
    },
    {
      task: 'Task3', data: [
        { category: 'HKU', action: types.UPDATE_PUBLIC_UNIVERSITIES_HKU },
        { category: 'CUHK', action: types.UPDATE_PUBLIC_UNIVERSITIES_CUHK },
        { category: 'HKUST', action: types.UPDATE_PUBLIC_UNIVERSITIES_HKUST },
        { category: 'CityU', action: types.UPDATE_PUBLIC_UNIVERSITIES_CITYU },
        { category: 'HKBU', action: types.UPDATE_PUBLIC_UNIVERSITIES_HKBU },

        { category: 'PolyU', action: types.UPDATE_PUBLIC_UNIVERSITIES_POLYU },
        { category: 'OUHK', action: types.UPDATE_PUBLIC_UNIVERSITIES_OUHK },
        { category: 'LU', action: types.UPDATE_PUBLIC_UNIVERSITIES_LU },
        { category: 'EdUHK', action: types.UPDATE_PUBLIC_UNIVERSITIES_EDUHK },
        { category: 'HKAPA', action: types.UPDATE_PUBLIC_UNIVERSITIES_HKAPA },
      ]
    },
  ],
  'task4': [
    { task: 'Task4', data: [{ category: 'Mainland China', action: types.UPDATE_MAINLAND_CHINA }], },
    { task: 'Task4', data: [{ category: 'Mainlanders', action: types.UPDATE_MAINLANDERS }], },
    {
      task: 'Task4', data: [
        { category: 'First-tier', action: types.UPDATE_MAINLAND_CITIES_FIRST },
        { category: 'New first-tier', action: types.UPDATE_MAINLAND_CITIES_NEW },
        { category: 'Second-tier', action: types.UPDATE_MAINLAND_CITIES_SECOND }
      ],
    },
    { task: 'Task4', data: [{ category: 'Central government', action: types.UPDATE_CENTRAL_GOVERNMENT }], },
  ],
  'task5': [
    {
      task: 'Task5', data: [
        { category: 'Universal suffrage', action: types.UPDATE_POLITICAL_ISSUES_UNIVERSAL },
        { category: 'One Country, Two Systems', action: types.UPDATE_POLITICAL_ISSUES_COUNTRY }
      ],
    },
    {
      task: 'Task5', data: [
        { category: 'Greater Bay Area', action: types.UPDATE_ECONOMIC_ISSUES_GREATER },
        { category: 'Housing price', action: types.UPDATE_ECONOMIC_ISSUES_HOUSING }
      ],
    },
    {
      task: 'Task5', data: [
        { category: 'Minimum wage', action: types.UPDATE_LIVELIHOOD_ISSUES_MINIMUM },
        { category: 'Mainland immigrants', action: types.UPDATE_LIVELIHOOD_ISSUES_MAINLAND },
        { category: 'Environment protection', action: types.UPDATE_LIVELIHOOD_ISSUES_ENVIRONMENT },
        { category: 'Income inequality', action: types.UPDATE_LIVELIHOOD_ISSUES_INCOME },
        { category: 'LGBTQ', action: types.UPDATE_LIVELIHOOD_ISSUES_LGBTO }
      ],
    },
  ],
  'task6': [
    {
      task: 'Task6', data: [
        { category: 'PARKnSHOP', action: types.UPDATE_LOCAL_RETAIL_PARKNSHOP },
        { category: 'Wellcome', action: types.UPDATE_LOCAL_RETAIL_WELLCOME },
        { category: '7-11', action: types.UPDATE_LOCAL_RETAIL_711 },
        { category: 'Circle-K', action: types.UPDATE_LOCAL_RETAIL_CIRCLEK },
        { category: 'Mannings', action: types.UPDATE_LOCAL_RETAIL_MANNINGS },
        { category: 'Watsons', action: types.UPDATE_LOCAL_RETAIL_WASTSONS },
        { category: 'CR Vanguard', action: types.UPDATE_LOCAL_RETAIL_VANGUARD },
        { category: 'Sogo', action: types.UPDATE_LOCAL_RETAIL_SOGO },
        { category: 'Wing On', action: types.UPDATE_LOCAL_RETAIL_WING },
        { category: 'Sincere', action: types.UPDATE_LOCAL_RETAIL_SINCERE },
        { category: 'CitySuper', action: types.UPDATE_LOCAL_RETAIL_SUPER },
        { category: 'Marks & Spencer', action: types.UPDATE_LOCAL_RETAIL_MARKS },
        { category: 'YATA', action: types.UPDATE_LOCAL_RETAIL_YATA },
        { category: 'Japan Home Centre', action: types.UPDATE_LOCAL_RETAIL_JAPAN },
        { category: 'DCH Food', action: types.UPDATE_LOCAL_RETAIL_DCH },
      ],
    },
    {
      task: 'Task6', data: [
        { category: 'Apple', action: types.UPDATE_INTERNATIONAL_COMPANIES_APPLE },
        { category: 'Google', action: types.UPDATE_INTERNATIONAL_COMPANIES_GOOGLE },
        { category: 'Microsoft', action: types.UPDATE_INTERNATIONAL_COMPANIES_MICROSOFT },
        { category: 'Amazon', action: types.UPDATE_INTERNATIONAL_COMPANIES_AMAZON },
        { category: 'Facebook', action: types.UPDATE_INTERNATIONAL_COMPANIES_FACEBOOK },
        { category: 'Coca-Cola', action: types.UPDATE_INTERNATIONAL_COMPANIES_COCACOLA },
        { category: 'Samsung', action: types.UPDATE_INTERNATIONAL_COMPANIES_SAMSUNG },
        { category: 'Disney', action: types.UPDATE_INTERNATIONAL_COMPANIES_DISNEY },
        { category: 'Toyota', action: types.UPDATE_INTERNATIONAL_COMPANIES_TOYOTA },
        { category: 'McDonald', action: types.UPDATE_INTERNATIONAL_COMPANIES_MCDONALD },
        { category: 'AT&T', action: types.UPDATE_INTERNATIONAL_COMPANIES_ATT },
        { category: 'Louis Vuitton', action: types.UPDATE_INTERNATIONAL_COMPANIES_LV },
        { category: 'Intel', action: types.UPDATE_INTERNATIONAL_COMPANIES_INTEL },
        { category: 'Nike', action: types.UPDATE_INTERNATIONAL_COMPANIES_NIKE },
        { category: 'Cisco', action: types.UPDATE_INTERNATIONAL_COMPANIES_CISCO },
      ],
    },
    {
      task: 'Task6', data: [
        { category: 'STATE GRID Corporation of China', action: types.UPDATE_MAINLAND_COMPANIES_STATE },
        { category: 'Sinopec', action: types.UPDATE_MAINLAND_COMPANIES_WELLCOME },
        { category: 'Cnpc', action: types.UPDATE_MAINLAND_COMPANIES_CNPC },
        { category: 'ICBC', action: types.UPDATE_MAINLAND_COMPANIES_ICBC },
        { category: 'CSCEC', action: types.UPDATE_MAINLAND_COMPANIES_CSCEC },
        { category: 'Ping An Insurance', action: types.UPDATE_MAINLAND_COMPANIES_PINGAN },
        { category: 'CCB', action: types.UPDATE_MAINLAND_COMPANIES_CCB },
        { category: 'SAICMOTOR', action: types.UPDATE_MAINLAND_COMPANIES_SAICMOTOR },
        { category: 'ABC', action: types.UPDATE_MAINLAND_COMPANIES_ABC },
        { category: 'China Life', action: types.UPDATE_MAINLAND_COMPANIES_LIFE },
        { category: 'BOC', action: types.UPDATE_MAINLAND_COMPANIES_BOC },
        { category: 'China Mobile', action: types.UPDATE_MAINLAND_COMPANIES_MOBILE },
        { category: 'DFMC', action: types.UPDATE_MAINLAND_COMPANIES_DFMC },
        { category: 'Huawei', action: types.UPDATE_MAINLAND_COMPANIES_HUAWEI },
        { category: 'Suning', action: types.UPDATE_MAINLAND_COMPANIES_SUNING },
      ],
    },
  ],
}

const viewConfig = {
  pageSize: 20
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';

export {
  siteConfig,
  systemConfig,
  themeConfig,
  language,
  viewConfig,
  asyncConfig,
  optionsConfig
};
