import { all, takeEvery, put, fork, call, actionChannel, take, flush, select } from 'redux-saga/effects';
import api from '../helpers/api'
import * as types from '../constants/ActionTypes';
import { toTreeData } from '../utils/utility';
import { optionsConfig } from '../config'

import {
    setError,
    clearRequesting,
    setRequesting,
} from '../actions/helper';
//{ name: 'mentions', url: '/rateofmentions' },
const analysis = { name: 'analysis', url: '/sentimentanalysis' }
const mentions = { name: 'mentions', url: '/rateofmentions' }

function* requestAnalysisOption(payload) {
    const { url, task, category, action, name } = payload;
    const body = {
        'task': task,
        'category': category
    }
    yield put(setRequesting());
    const rs = yield call(api, url, body);

    console.log('-------rsanalysis', rs)

    if (!rs.hasOwnProperty('error')) {
        yield put({
            type: action,
            name: name,
            data: rs,

        });
        yield put(clearRequesting());
        return false;

    } else {
        yield put(setError({ type: 'error', message: rs.error }));
        return true
    }
}


function* takeOptionSyncStart() {
    yield takeEvery(types.SYNC_OPTION_START, function* (record) {
        let params = record.params
        const requestType = params.type;
        let optionData = optionsConfig[params.kind][params.num];
        let arr = [];
        const dataInit = requestType === 'analysis' ? analysis : mentions;
        optionData.data.map(item => {
            let obj = {
                ...item,
                task: optionData.task,
                name: dataInit.name,
                url: dataInit.url,
            }
            arr.push(obj)
            return arr
        })
        for (let i = 0; i < arr.length; i++) {
            let payload = arr[i]
            yield put({ type: types.SYNC_OPTION_CHANNEL, payload: payload });
        }

    });
}



function* watchOptionSyncRequestsChannel() {
    // 1- 为 REQUEST actions 创建一个 channel
    const requestChan = yield actionChannel(types.SYNC_OPTION_CHANNEL);
    while (true) {
        const { payload } = yield take(requestChan);
        const hasError = yield call(requestAnalysisOption, payload);
        console.log('hasError', hasError)

        if (hasError) {
            const actions = yield flush(requestChan);
        }
    }
}

export default function* rootSaga() {
    yield all([
        fork(takeOptionSyncStart),
        fork(watchOptionSyncRequestsChannel)
    ])
}