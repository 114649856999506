import * as types from '../constants/ActionTypes';

export function setError(
    error
) {
    return {
        type: types.SET_ERROR,
        error
    };
}

export function clearError(){
    return {
        type: types.CLEAR_ERROR,
    };
}


export function setModalError(
    error
) {
    return {
        type: types.SET_MODAL_ERROR,
        error
    };
}

export function clearModalError(){
    return {
        type: types.CLEAR_MODAL_ERROR,
    };
}

export function setRequesting() {
    return {
        type: types.SET_REQUESTING
    };
}

export function clearRequesting() {
    return {
        type: types.CLEAR_REQUESTING
    };
}

export function setSubmitting() {
    return {
        type: types.SET_SUBMITTING
    };
}

export function clearSubmitting() {
    return {
        type: types.CLEAR_SUBMITTING
    };
}

export function showPanel(
    name,
    props,
    callback
) {
    return {
        type: types.SHOW_OPERATE_PANEL,
        name,
        props,
        callback
    };
}

export function hidePanel(){
    return {
        type: types.HIDE_OPERATE_PANEL,
    };
}

export function panelDidMount(){
    return {
        type: types.MOUNT_OPERATE_PANEL,
    };
}

export function panelDidUnmount(){
    return {
        type: types.UNMOUNT_OPERATE_PANEL,
    };
}

export function removeOption(
    keys
) {
    return {
        type: types.REMOVE_OPTION,
        keys
    };
}

export function queryOption(
    key,
    cascader
) {
    return {
        type: types.QUERY_OPTION,
        key,
        cascader
    };
}

export function requestOption(
    key
) {
    return {
        type: types.REQUEST_OPTION,
        key
    };
}
