import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    mentions: [],
    analysis: []
});

export default function taskReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_CHIEF_COURT: {
            return state
                .set(action.name, action.data)
        }
        default:
            return state;
    }
}