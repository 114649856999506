import React from 'react';
import ReactDOM from 'react-dom';
import RootApp from './rootApp';
import registerServiceWorker from './registerServiceWorker';
import './resources/helper.css';
import './resources/ny.css';

import { hot } from 'react-hot-loader'

ReactDOM.render(
    <RootApp />,
    document.getElementById('root')
);

/*
if (module.hot) {
    module.hot.accept('./rootApp.js', () => {
        const HotApp = require('./rootApp').default;
        ReactDOM.render(<HotApp />, document.getElementById('root'));
    });
}*/

//registerServiceWorker();
