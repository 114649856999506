import React from 'react';
import styled from 'styled-components';
import Loading from './loader';

const RestoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  
  .ant-btn {
    padding-bottom: 1px;
  }
  .searchContent {
    .ant-form {
      .ant-form-item {
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
      }
    }
  }
`;

const Restore = () => (
    <RestoreWrapper>
        <Loading />
    </RestoreWrapper>
);

export default Restore;
