import * as types from '../constants/ActionTypes';

export function checkAuthorization() {
    return {
        type: types.CHECK_AUTHORIZATION
    };
}

export function login(
    token
) {
    return {
        type: types.LOGIN_REQUEST,
        token
    };
}

export function notLogin() {
    return {
        type: types.NOTLOGIN
    };
}

export function logout() {
    return {
        type: types.LOGOUT
    };
}

export function updatMenu(
    key,
    items
) {
    return {
        key,
        items,
        type: types.UPDATE_MENU_ITEMS
    };
}

export function setToken(
    token
) {
    return {
        type: types.SET_TOKEN,
        token
    };
}
