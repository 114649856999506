import React from 'react';
import { Layout } from 'antd';
import { ThemeProvider } from 'styled-components';
import themes from '../../config/themes';
import AppHolder from './commonStyle';
import TopBar from '../../containers/TopBar';
import AppRouter from "./appRouter";
// import PanelContainer from '../../containers/Panel';
import AppAuth from "../../helpers/appAuth";
import './global.css';

const { Content } = Layout;

export class App extends React.Component {
  constructor(props){
    super(props)

  }


  

  render() {
    return (
          <ThemeProvider theme={themes.themedefault}>
            <AppHolder>
            <TopBar {...this.props} />
              <Layout style={{  overflowX: 'hidden' }}>
                <Layout style={{ overflowX: 'hidden' }}>
                    <Layout
                        className="contentMainLayout"
                    >
                        <Content
                            className="layoutContent"
                        >
                            <AppAuth>
                                <AppRouter />
                            </AppAuth>
                        </Content>
                    </Layout>
                </Layout>
              </Layout>
              {/* <PanelContainer /> */}
            </AppHolder>
          </ThemeProvider>
    );
  }
}

export default App;
