import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import configureStore from "./configureStore";
import { createBrowserHistory as createHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reducers from "../reducers/index";
import rootSaga from "../sagas/index";
import ReduxPersist from "../config/reduxPersist";

const createStore = () => {
    const history = createHistory();

    const rootReducer = combineReducers({...reducers, router: connectRouter(history)});
    const persistConfig = ReduxPersist.storeConfig;
    const finalReducers = persistReducer(persistConfig, rootReducer);

    const routeMiddleware = routerMiddleware(history);
    let { store, sagasManager, sagaMiddleware, persistor } = configureStore(finalReducers, rootSaga, routeMiddleware);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('../reducers/index').reducers;
            store.replaceReducer(nextRootReducer);

            const newYieldedSagas = require('../sagas/index').default;
            sagasManager.cancel();
            sagasManager.done.then(() => {
                sagasManager = sagaMiddleware.run(newYieldedSagas);
            })
        })
    }

    return {store, history, persistor};
};

const { store, history, persistor } = createStore();
export { store, history, persistor };