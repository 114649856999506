import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    carrie: {
        'mentions': [],
        'analysis': []
    },
    leung: {
        'mentions': [],
        'analysis': []
    },
    donald: {
        'mentions': [],
        'analysis': []
    },
    tung: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_CHIEF_EXECUTIVE_CARRIE: {
            let init = state.get('carrie');
            init[action.name] = action.data;
            return state
                .set('carrie', init)
        }


        case types.UPDATE_CHIEF_EXECUTIVE_LEUNG: {
            let init = state.get('leung');
            init[action.name] = action.data;
            return state
                .set('leung', init)
        }


        case types.UPDATE_CHIEF_EXECUTIVE_DONALD: {
            let init = state.get('donald');
            init[action.name] = action.data;
            return state
                .set('donald', init)
        }


        case types.UPDATE_CHIEF_EXECUTIVE_TUNG: {
            let init = state.get('tung');
            init[action.name] = action.data;
            return state
                .set('tung', init)
        }


        default:
            return state;
    }
}