import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    localist: {
        'mentions': [],
        'analysis': []
    },
    panDemocratic: {
        'mentions': [],
        'analysis': []
    },
    moderate: {
        'mentions': [],
        'analysis': []
    },
    proEstablishment: {
        'mentions': [],
        'analysis': []
    },
});

export default function HKReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_POLITICAL_PARTIES_LOCALIST: {
            let init = state.get('localist');
            init[action.name] = action.data;
            return state
                .set('localist', init)
        }


        case types.UPDATE_POLITICAL_PARTIES_PAN_DEMOCRATIC: {
            let init = state.get('panDemocratic');
            init[action.name] = action.data;
            return state
                .set('panDemocratic', init)
        }


        case types.UPDATE_POLITICAL_PARTIES_MODERATE: {
            let init = state.get('moderate');
            init[action.name] = action.data;
            return state
                .set('moderate', init)
        }
        case types.UPDATE_POLITICAL_PARTIES_PRO_ESTABLISHMENT: {
            let init = state.get('proEstablishment');
            init[action.name] = action.data;
            return state
                .set('proEstablishment', init)
        }



        default:
            return state;
    }
}