import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = new Map({
    hasError: false,
    error: null,
    isRequesting: false,
    isSubmitting: false,
    hasModalError: false,
    modalError: null,

});
export default function (state = initState, action) {
    switch (action.type) {
        case types.SET_ERROR:
            return state
                .set('hasError', true)
                .set('error', action.error);


        case types.CLEAR_ERROR:
            return state
                .set('hasError', false)
                .set('error', null)
                .set('isSubmitting', false)
                .set('isRequesting', false);

        case types.SET_MODAL_ERROR:
            return state
                .set('hasModalError', true)
                .set('modalError', action.error);

        case types.CLEAR_MODAL_ERROR:
            return state
                .set('hasModalError', false)
                .set('modalError', null)
                .set('isSubmitting', false)
                .set('isRequesting', false);

        case types.SET_REQUESTING:
            return state.set('isRequesting', true);

        case types.CLEAR_REQUESTING:
            return state.set('isRequesting', false);

        case types.SET_SUBMITTING:
            return state.set('isSubmitting', true);

        case types.CLEAR_SUBMITTING:
            return state.set('isSubmitting', false);

        default:
            return state;
    }
}
