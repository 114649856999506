// Transition
export function transition(timing = 0.3) {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

export function background(timing = 1) {
    return `
      -webkit-animation: background ${timing}s infinite;
      -moz-animation: background ${timing}s infinite;
      -ms-animation: background ${timing}s infinite;
      -o-animation: background ${timing}s infinite;
      animation: background ${timing}s infinite;
  `;
}

// Ripple
export function ripple(timing = 0) {
    return `      
      -webkit-animation: ripple 4500ms ease-out ${timing}ms infinite;
      -moz-animation: ripple 4500ms ease-out ${timing}ms infinite;
      -ms-animation: ripple 4500ms ease-out ${timing}ms infinite;
      -o-animation: ripple 4500ms ease-out ${timing}ms infinite;
      animation: ripple 4500ms ease-out ${timing}ms infinite;
  `;
}

// Border Radius
export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

// Box Shadow
export function boxShadow(shadow = 'none') {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}
