import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    mentions:[]
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.UPDATE_SENTIMENT_NEWS:
            return state
                .set('mentions', action.data)

        // case types.UPDATE_SENTIMENT_NON_NEWS:
        //     return state
        //         .set('no_news', action.data.non)

        // case types.UPDATE_SENTIMENT_ALL:
        //     return state
        //         .set('all', action.data.all)


        default:
            return state;
    }
}