import moment from 'moment';
import CryptoJS from 'crypto-js'

const crypKey = '1AB4123412EF23CD';
const crypIV = 'A123B4C123D4F12E';

export function isNull(data) {
    if(!data) return true;
    if( data instanceof Array) return data.length === 0;
    if( data instanceof Object) return Object.keys(data).length === 0;
    if(typeof data === 'string' ) return data === '';
}

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function saveToken(token) {
    localStorage.setItem('id_token', getAesString(JSON.stringify(token)));
}

export function updateToken(token) {
    const old = getToken();
    Object.keys(token).map(key => {
        if(old.hasOwnProperty(key)) {
            old[key] = token[key];
        }
        else {
            clearToken();
            return null;
        }
    });
    saveToken(token);
}

export function getToken() {
    try{
        return JSON.parse(getDAesString(localStorage.getItem('id_token')));
    }
    catch (e) {
        clearToken();
        return null;
    }
}

export function getAesString(data) {
    const key = CryptoJS.enc.Utf8.parse(crypKey);
    const iv = CryptoJS.enc.Utf8.parse(crypIV);
    const srcs = CryptoJS.enc.Utf8.parse(data);

    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

export function getDAesString(encrypted){
    const key  = CryptoJS.enc.Utf8.parse(crypKey);
    const iv   = CryptoJS.enc.Utf8.parse(crypIV);

    const decrypted =CryptoJS.AES.decrypt(encrypted, key, {
        iv:iv,
        mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
}

export function randomNum(minNum,maxNum){
    switch(arguments.length){
        case 1:
            return parseInt(Math.random()*minNum+1,10);
            break;
        case 2:
            return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10);
            break;
        default:
            return 0;
            break;
    }
}

export function getStringLength(str) {
    return str.replace(/[\u0391-\uFFE5]/g,"aa").length;  //先把中文替换成两个字节的英文，在计算长度
}

export function substrWithDot(str, len) {
    const length = getStringLength(str);
    if(length <= len) {
        return str;
    }
    let strlen = 0;
    let s = "";
    for(let i = 0;i < length; i++) {
        s = s + str.charAt(i);
        if (str.charCodeAt(i) > 128) {
            strlen = strlen + 2;
            if(strlen >= len){
                return s.substring(0,s.length-1) + "...";
            }
        } else {
            strlen = strlen + 1;
            if(strlen >= len){
                return s.substring(0,s.length-2) + "...";
            }
        }
    }
    return s;
}

export function idGenerator(type) {
    const format = '[NY]-YYYYMMDD-HHmmSS-R(4)';
    let id = moment().format(format);

    let rand = id.match(/R\(\d{1}\)/g);
    rand = rand[0].match(/\d{1}/g);

    const randomNumb = rand[0];
    if(randomNumb){
        const min = Math.pow(10, randomNumb-1);
        let max = min * 9;

        let more = min/10;
        while(more > 1){
            max += more * 9 + 9;
            more = more/10;
        }
        id = id.replace(/R\(\d{1}\)/g, randomNum(min, max));
    }
    return id;
}

export function uuid(bit){
    let template = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"; //36位
    if(bit===16) {
        template = 'xxxxxxxxxxxxxxxx'
    }
    let d = new Date().getTime();
    return template.replace(/[xy]/g, function(c) {
        const r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16).toLowerCase();
    });
}

export function isUuid(uuid) {
    if(uuid.length !== 36) return false;
    const reg = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/;
    return reg.test(uuid);
}

export function orderId(type){
    const reg = /[0-9]{6}/;
    if(!reg.test(type)) {
        //return null;
    }
    return type + moment().format("YYYYMMDDHHmmssSSS") + Math.floor(Math.random()*4000+1000);
}

export function messageId() {
    return parseInt(moment().format('x'));
}

export function stringFormat(str, args) {
    let result = str;
    if(arguments.length < 2) {
        return result;
    }
    if (typeof (args) === "object") {
        for (let key in args) {
            if(args.hasOwnProperty(key)){
                const reg = new RegExp("(\\{" + key + "\\})", "g");
                result = result.replace(reg, args[key]);
            }
        }
    }
    return result;
}

export function arrToObj(arr, key) {
    const obj = {};
    try {
        arr.map(value => {
            obj[value[key]] = value;
        });
        return obj;
    } catch (err) {
        return obj;
    }
}

export function objToArr(obj) {
    const arr = [];
    try {
        Object.keys(obj).map(k => {
            arr.push(obj[k]);
        });
        return arr;
    } catch (err) {
        return arr;
    }
}

export function toTreeData (data, attributes) {
    let resData = data;
    let tree = [];
    let key = 0;

    for (let i = 0; i < resData.length; i++) {
        if (resData[i][attributes.pid] === attributes.rootId) {
            let obj = {key: key};
            obj.data = resData[i];
            Object.keys(attributes).map( k => {
                if(k !== 'children' && k !== 'rootId') {
                    obj[k] = resData[i] && resData[i][attributes[k]] ? resData[i][attributes[k]] : null
                }
            });
            tree.push(obj);
            resData.splice(i, 1);
            i--;
            key++;
        }
    }
    run(tree);
    function run(chiArr) {
        if (resData.length !== 0) {
            for (let i = 0; i < chiArr.length; i++) {
                key = 0;
                for (let j = 0; j < resData.length; j++) {
                    if (chiArr[i].id && chiArr[i].id === resData[j][attributes.pid]) {
                        let obj = {key: key};
                        obj.data = resData[j];
                        Object.keys(attributes).map( k => {
                            if(k !== 'children' && k !== 'rootId') {
                                obj[k] = resData[j][attributes[k]] ? resData[j][attributes[k]] : null
                            }
                        });
                        if(chiArr[i][attributes.children] === undefined) {
                            chiArr[i][attributes.children] = [];
                        }
                        chiArr[i][attributes.children].push(obj);
                        resData.splice(j, 1);
                        j--;
                        key++;
                    }
                }
                chiArr[i].subCounts = chiArr[i][attributes.children] ? chiArr[i][attributes.children].length : 0;
                if(chiArr[i][attributes.children] !== undefined) {
                    run(chiArr[i][attributes.children]);
                }
            }
        }
    }

    return tree;
}

export function base64Decode(input) {
    // private property
    const _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    let output = "";
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        output = output + String.fromCharCode(chr1);
        if (enc3 !== 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
            output = output + String.fromCharCode(chr3);
        }
    }
    output = _utf8_decode(output);
    return output;

    function _utf8_decode(utftext) {
        let string = "";
        let i = 0;
        let c = 0;
        let c1 = 0;
        let c2 = 0;
        while ( i < utftext.length ) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i+1);
                let c3 = utftext.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }
}

export function deepCopy(o) {
    let n;
    if (o instanceof Array) {
        let n = [];
        for (let i = 0; i < o.length; ++i) {
            n[i] = deepCopy(o[i]);
        }
        return n;
    } else if (o instanceof Function) {
         n = new Function("return " + o.toString())();
        return n
    } else if (o instanceof Object) {
         n = {};
        for (let i in o) {
            n[i] = deepCopy(o[i]);
        }
        return n;
    } else {
        return o;
    }
}

export function isEqual (x, y) {
    let i, l, leftChain, rightChain;
    function compare2Objects(x, y) {
        let p;
        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof(x[p])) {
                case 'object':
                case 'function':

                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }
    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }
    for (let i = 1, l = arguments.length; i < l; i++) {
        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }
    return true;
}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function calculateDegree(value) {
    const degree = parseInt(value);
    const min = parseInt((value - degree) * 60);
    const sec = parseInt((value - degree) * 3600 - min * 60);
    return degree + '°' + min + '′' + sec + '″';
}

export function calculateNumeric(value) {
    const degree = value.split("°")[0];
    const min = value.split("°")[1].split("′")[0];
    const sec = value.split("°")[1].split("′")[1].split('″')[0];
    return Math.abs(degree) + (Math.abs(min)/60 + Math.abs(sec)/3600).toFixed(7);
}

export function degreeToObject(value) {
    if(value.indexOf("°") === -1 || value.indexOf("′") === -1 || value.indexOf("″") === -1 ) {
        return {
            degree: null,
            minute: null,
            second: null,
        }
    }
    const degree = value.split("°")[0];
    const min = value.split("°")[1].split("′")[0];
    const sec = value.split("°")[1].split("′")[1].split('″')[0];
    return {
        degree: degree === 'null' ? null : degree,
        minute: min === 'null' ? null : min,
        second: sec === 'null' ? null : sec,
    }
}

export function cascaderValue(tree, id) {
    if(!tree || !id) return [];

    let value = [];
    let hit = false;

    for (let i=0,lenOne=tree.length; i<lenOne; i++){
        const oneLevel = tree[i];
        value.push(oneLevel.label);
        if(oneLevel.id === id) {
            hit = true;
            break;
        }

        if(oneLevel.children && oneLevel.children.length === 0) {
            value = [];
        }
        else {
            const twoChildren = oneLevel.children;
            if(!twoChildren) continue;

            for (let j=0,lenTwo=twoChildren.length; j<lenTwo; j++){
                const twoLevel = twoChildren[j];
                value.push(twoLevel.label);
                if(twoLevel.id === id) {
                    hit = true;
                    break;
                }

                if(twoLevel.children && twoLevel.children.length === 0) {
                    value.pop();
                }
                else {
                    const threeChildren = twoLevel.children;
                    if(!threeChildren) {
                        value.pop();
                        continue;
                    }

                    for (let k=0,lenThree=threeChildren.length; k<lenThree; k++){
                        const threeLevel = threeChildren[k];
                        if(threeLevel.id === id) {
                            value.push(threeLevel.label);
                            hit = true;
                            break;
                        }
                    }

                    if(hit) break; else value.pop();
                }
            }

            if(hit) break; else value = [];
        }
    }
    return hit ? value : [];
}

export function cascaderData(tree, id) {
    if(!tree || !id) return {};

    for (let i=0,lenOne=tree.length; i<lenOne; i++){

        const oneLevel = tree[i];

        if(oneLevel.id === id) {
            return oneLevel.data ? oneLevel.data : oneLevel;
        }

        if(oneLevel.children && oneLevel.children.length > 0) {
            const twoChildren = oneLevel.children;

            for (let j=0,lenTwo=twoChildren.length; j<lenTwo; j++){
                const twoLevel = twoChildren[j];

                if(twoLevel.id === id) {
                    return twoLevel.data ? twoLevel.data : twoLevel;
                }

                if(twoLevel.children && twoLevel.children.length > 0) {
                    const threeChildren = twoLevel.children;

                    for (let k=0,lenThree=threeChildren.length; k<lenThree; k++){
                        const threeLevel = threeChildren[k];

                        if(threeLevel.id === id) {
                            return threeLevel.data ? threeLevel.data : threeLevel;
                        }
                    }
                }
            }
        }
    }
    return {};
}

//format
export function padding(num, length) {
    for(let len = (num + "").length; len < length; len = num.length) {
        num = "0" + num;
    }
    return num;
}