import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    newspaper: {
        'mentions': [],
        'analysis': []
    },
    radio: {
        'mentions': [],
        'analysis': []
    },
    television: {
        'mentions': [],
        'analysis': []
    },
    online: {
        'mentions': [],
        'analysis': []
    },
});

export default function HKReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_THE_MEDIA_NEWSPAPER: {
            let init = state.get('newspaper');
            init[action.name] = action.data;
            return state
                .set('newspaper', init)
        }


        case types.UPDATE_THE_MEDIA_RADIO: {
            let init = state.get('radio');
            init[action.name] = action.data;
            return state
                .set('radio', init)
        }


        case types.UPDATE_THE_MEDIA_TELEVISION: {
            let init = state.get('television');
            init[action.name] = action.data;
            return state
                .set('television', init)
        }
        case types.UPDATE_THE_MEDIA_ONLINE: {
            let init = state.get('online');
            init[action.name] = action.data;
            return state
                .set('online', init)
        }



        default:
            return state;
    }
}