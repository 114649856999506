import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    STATE: {
        'mentions': [],
        'analysis': []
    },
    Sinopec: {
        'mentions': [],
        'analysis': []
    },
    Cnpc: {
        'mentions': [],
        'analysis': []
    },
    ICBC: {
        'mentions': [],
        'analysis': []
    },
    CSCEC: {
        'mentions': [],
        'analysis': []
    },
    PingAn: {
        'mentions': [],
        'analysis': []
    },
    CCB: {
        'mentions': [],
        'analysis': []
    },
    SAICMOTOR: {
        'mentions': [],
        'analysis': []
    },
    ABC: {
        'mentions': [],
        'analysis': []
    },
    Life: {
        'mentions': [],
        'analysis': []
    },
    BOC: {
        'mentions': [],
        'analysis': []
    },
    Mobile: {
        'mentions': [],
        'analysis': []
    },
    DFMC: {
        'mentions': [],
        'analysis': []
    },
    Huawei: {
        'mentions': [],
        'analysis': []
    },
    Suning: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_MAINLAND_COMPANIES_STATE: {
            let init = state.get('STATE');
            init[action.name] = action.data;
            return state
                .set('STATE', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_WELLCOME: {
            let init = state.get('Sinopec');
            init[action.name] = action.data;
            return state
                .set('Sinopec', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_CNPC: {
            let init = state.get('Cnpc');
            init[action.name] = action.data;
            return state
                .set('Cnpc', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_ICBC: {
            let init = state.get('ICBC');
            init[action.name] = action.data;
            return state
                .set('ICBC', init)
        }

        case types.UPDATE_MAINLAND_COMPANIES_CSCEC: {
            let init = state.get('CSCEC');
            init[action.name] = action.data;
            return state
                .set('CSCEC', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_PINGAN: {
            let init = state.get('PingAn');
            init[action.name] = action.data;
            return state
                .set('PingAn', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_CCB: {
            let init = state.get('CCB');
            init[action.name] = action.data;
            return state
                .set('CCB', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_SAICMOTOR: {
            let init = state.get('SAICMOTOR');
            init[action.name] = action.data;
            return state
                .set('SAICMOTOR', init)
        }

        case types.UPDATE_MAINLAND_COMPANIES_ABC: {
            let init = state.get('ABC');
            init[action.name] = action.data;
            return state
                .set('ABC', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_LIFE: {
            let init = state.get('Life');
            init[action.name] = action.data;
            return state
                .set('Life', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_BOC: {
            let init = state.get('BOC');
            init[action.name] = action.data;
            return state
                .set('BOC', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_MOBILE: {
            let init = state.get('Mobile');
            init[action.name] = action.data;
            return state
                .set('Mobile', init)
        }

        case types.UPDATE_MAINLAND_COMPANIES_DFMC: {
            let init = state.get('DFMC');
            init[action.name] = action.data;
            return state
                .set('DFMC', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_HUAWEI: {
            let init = state.get('Huawei');
            init[action.name] = action.data;
            return state
                .set('Huawei', init)
        }


        case types.UPDATE_MAINLAND_COMPANIES_SUNING: {
            let init = state.get('Suning');
            init[action.name] = action.data;
            return state
                .set('Suning', init)
        }



        default:
            return state;
    }
}