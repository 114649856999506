import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Icon, Menu, Dropdown } from 'antd';
import TopbarWrapper from './topbar.style';
import styled from 'styled-components';
const { SubMenu } = Menu;
const { Header } = Layout;

const companies = [
  {
    enterprise_id: '1',
    enterprise_name: '华润包装材料有限公司',
  },
  {
    enterprise_id: '2',
    enterprise_name: '华润特种印刷有限公司',
  }
];

const littleBlockMsg = [
  {
    title: '首頁',
    second_title: 'Home',
    disabled: true,
    url: '/'

  },
  {
    title: '今日熱點',
    second_title: 'Talk of the Town',
    disabled: true,
    url: '/talkOfTheTown'
  },
  {
    title: '民意趨勢',
    second_title: 'Public Opinion Trend',
    disabled: false,
    url: '#',
    menuData: [
      { chinese: '活躍程度', english: 'Activity level', url: '/publicOpinion/activeLevel', },
      { chinese: '熱門板塊', english: 'Popular sections', url: '/publicOpinion/popularSections' },
      { chinese: '每月熱文', english: 'Hot posts', url: '/publicOpinion/hotPosts' },
      { chinese: '每月熱詞', english: 'Hot words', url: '/publicOpinion/hotWords' },
      { chinese: '情緒指數', english: 'Public sentiment index', url: '/publicOpinion/sentiment' },
      { chinese: '粗口指數', english: 'Swearing index', url: '/publicOpinion/swearing/mentions' },
      { chinese: '消費者信心', english: 'Consumer confidence', url: '/publicOpinion/comsumerConfidence' },
    ]

  },
  {
    title: '公眾人物民望',
    second_title: 'Public Figure Popularity',
    disabled: false,
    url: '#',
    menuData: [
      {
        chinese: '行政長官',
        english: 'Chief executive',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/publicFigurePopularity/chiefExecutive/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/publicFigurePopularity/chiefExecutive/analysis' }
        ]
      },
      {
        chinese: '立法會議員(2016-2020)',
        english: 'LegCo members（2016-2020)',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/publicFigurePopularity/legcoMembers/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/publicFigurePopularity/legcoMembers/analysis' }
        ]
      },
      {
        chinese: '司長和局長',
        english: 'Principal officials',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/publicFigurePopularity/principalOfficials/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/publicFigurePopularity/principalOfficials/analysis' }
        ]
      },
      {
        chinese: '首席法官',
        english: 'Chief of the court of final',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/publicFigurePopularity/chiefCourt/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/publicFigurePopularity/chiefCourt/analysis' }
        ]
      }
    ]

  },
  {
    title: '機構團體民望',
    second_title: 'Organization Popularity',
    disabled: false,
    url: '#',
    menuData: [
      {
        chinese: '政府機構',
        english: 'Government branches',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/organizationPopularity/governmentBranches/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/organizationPopularity/governmentBranches/analysis' }
        ]
      },
      {
        chinese: '政黨組織',
        english: 'Political parties',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/organizationPopularity/politicalParties/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/organizationPopularity/politicalParties/analysis' }
        ]
      },
      {
        chinese: '紀律部隊',
        english: 'Disciplined services',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/organizationPopularity/disciplinedServices/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/organizationPopularity/disciplinedServices/analysis' }
        ]
      },
      {
        chinese: '傳媒機构',
        english: 'Media',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/organizationPopularity/theMedia/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/organizationPopularity/theMedia/analysis' }
        ]
      },
      {
        chinese: '公立大學',
        english: 'Public universities',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/organizationPopularity/publicUniversities/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/organizationPopularity/publicUniversities/analysis' }
        ]
      },

    ]
  },
  {
    title: '大陸印象',
    second_title: 'Public Opinion toward the Mainland',
    disabled: false,
    url: '#',
    menuData: [
      {
        chinese: '中國大陸',
        english: 'Mainland China',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/mainland/mainlandChina/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/mainland/mainlandChina/analysis' }
        ]
      },
      {
        chinese: '大陸人',
        english: 'Mainlanders',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/mainland/mainlanders/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/mainland/mainlanders/analysis' }
        ]
      },
      {
        chinese: '內地城市',
        english: 'Mainland Cities',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/mainland/mainlandCities/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/mainland/mainlandCities/analysis' }
        ]
      },
      {
        chinese: '中央政府',
        english: 'Central Government',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/mainland/centralGovernment/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/mainland/centralGovernment/analysis' }
        ]
      }
    ]
  },
  {
    title: '社會議題',
    second_title: 'Social Issues',
    disabled: false,
    url: '#',
    menuData: [
      {
        chinese: '政治議題',
        english: 'Political Issues',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/socialIssues/political/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/socialIssues/political/analysis' }
        ]
      },
      {
        chinese: '經濟議題',
        english: 'Economic Issues',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/socialIssues/economic/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/socialIssues/economic/analysis' }
        ]
      },
      {
        chinese: '民生議題',
        english: 'Livelihood Issues',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/socialIssues/livelihood/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/socialIssues/livelihood/analysis' }
        ]
      }
    ]

  },
  {
    title: '企業形象',
    second_title: 'Corporate Image',
    disabled: false,
    url: '#',
    menuData: [
      {
        chinese: '本土零售企業',
        english: 'Local Retail',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/corporate/localRetail/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/corporate/localRetail/analysis' }
        ]
      },
      {
        chinese: '國際企業',
        english: 'International Companies',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/corporate/internationalCompanie/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/corporate/internationalCompanie/analysis' }
        ]
      },
      {
        chinese: '內地企業',
        english: 'Mainland Companies',
        url: '#',
        children: [
          { chinese: '提及率', english: 'Frequency of mentions', url: '/corporate/mainlandCompanies/mentions' },
          { chinese: '情感分析', english: 'Sentiment analysis', url: '/corporate/mainlandCompanies/analysis' }
        ]
      }
    ]
  },


]


class Topbar extends Component {

  state = {
    currentCompany: null,
    current: 'mail',

  };

  setCurrent = (current) => {
    this.setState({
      current: current
    })
  }


  render() {
    return (
      <TopbarWrapper>
        <Header className="topbar consoleTopbar">
          <p className='header_top'>
            <span className='top_left'>香港網絡民意數據挖掘計劃<br/>Hong Kong Online Public Opinion Data Mining Project</span>
            {/* <span className='top_right'>a GRF project funded by UGC</span> */}
          </p>
          <div className='header_content'>
            {littleBlockMsg.map((item, id) => <LittleTitle
              {...item}
              key={'block_' + id}
              history={this.props.history}
              current={this.state.current}
              setCurrent={this.setCurrent}
            />)}
            <div>

            </div>
          </div>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default Topbar;

const MenuRender = (props) => {

  return (
    <Menu >
      {
        props.menuData.map(item => {
          if (item.children) {
            return <SubMenu
              title={<span ><span >{item.chinese}</span><span className='e_span'>{item.english}</span></span>}
            >
              {
                item.children.map(ii => <Menu.Item onClick={() => { props.setCurrent(props.second_title); props.history.push(ii.url) }}><span>{ii.chinese}</span><span className='e_span'>{ii.english}</span> </Menu.Item>)
              }

            </SubMenu>
          } else {
            return <Menu.Item onClick={() => { props.setCurrent(props.second_title); props.history.push(item.url)  }}><span>{item.chinese}</span><span className='e_span'>{item.english}</span> </Menu.Item>
          }
        })
      }
    </Menu>
  )
}


const LittleTitle = (props) => {
  if (props.disabled) {
    return (
      <div className={`block_content  ${props.current === props.second_title ? 'block_selected' : null}`}
        onClick={() => { props.setCurrent(props.second_title); props.history.push(props.url) }}
      >
        <p className='first_title'>{props.title}</p>
        <div className='title_dash'></div>
        <p className='second_title'>{props.second_title}</p>

      </div>
    )
  } else {
    let menuList = <MenuRender
      menuData={props.menuData}
      history={props.history}
      second_title={props.second_title}
      current={props.current}
      setCurrent={props.setCurrent}
    />
    return (
      // /visible
      <Dropdown overlay={menuList} overlayClassName='drop_down'  >
        <div className={`block_content  ${props.current === props.second_title ? 'block_selected' : null}`}
          onClick={() => { props.setCurrent(props.second_title); props.history.push(props.url) }}
        >
          <p className='first_title'>{props.title}</p>
          <div className='title_dash'></div>
          <p className='second_title'>{props.second_title}</p>

        </div>
      </Dropdown>
    )
  }

}

