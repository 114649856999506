import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    universal: {
        'mentions': [],
        'analysis': []
    },
    country: {
        'mentions': [],
        'analysis': []
    }

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_POLITICAL_ISSUES_UNIVERSAL: {
            let init = state.get('universal');
            init[action.name] = action.data;
            return state
                .set('universal', init)
        }


        case types.UPDATE_POLITICAL_ISSUES_COUNTRY: {
            let init = state.get('country');
            init[action.name] = action.data;
            return state
                .set('country', init)
        }



        default:
            return state;
    }
}