import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    greater: {
        'mentions': [],
        'analysis': []
    },
    housing: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_ECONOMIC_ISSUES_GREATER: {
            let init = state.get('greater');
            init[action.name] = action.data;
            return state
                .set('greater', init)
        }


        case types.UPDATE_ECONOMIC_ISSUES_HOUSING: {
            let init = state.get('housing');
            init[action.name] = action.data;
            return state
                .set('housing', init)
        }




        default:
            return state;
    }
}