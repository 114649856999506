import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
    first: {
        'mentions': [],
        'analysis': []
    },
    new: {
        'mentions': [],
        'analysis': []
    },
    second: {
        'mentions': [],
        'analysis': []
    },
    step: 0

});

export default function chiefExecutiveasyncReducer(state = initState, action) {

    switch (action.type) {
        case types.UPDATE_MAINLAND_CITIES_FIRST: {
            let init = state.get('first');
            init[action.name] = action.data;
            return state
                .set('first', init)
        }


        case types.UPDATE_MAINLAND_CITIES_NEW: {
            let init = state.get('new');
            init[action.name] = action.data;
            return state
                .set('new', init)
        }


        case types.UPDATE_MAINLAND_CITIES_SECOND: {
            let init = state.get('second');
            init[action.name] = action.data;
            return state
                .set('second', init)
        }

        default:
            return state;
    }
}