import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = Map({
reply:[],
article:[]
});

export default function asyncReducer(state = initState, action) {
    switch (action.type) {
        case types.UPDATE_POPULAR_SECTIONS:
            return state
                .set('reply', action.data.reply)
                .set('article', action.data.article)

        default:
            return state;
    }
}