import * as types from '../constants/ActionTypes';

export function toggleCollapsed() {
    return {
        type: types.FOLD_CHANGE
    };
}

export function toggleOpenDrawer() {
    return {
        type: types.FOLD_OPEN_DRAWER
    };
}

export function toggleOpenSubDrawer() {
    return {
        type: types.FOLD_OPEN_SUB_DRAWER
    };
}

export function changeOpenKeys(
    openKeys
) {
    return {
        type: types.CHANGE_OPEN_KEYS,
        openKeys
    };
}

export function changeCurrent(
    current
) {
    return {
        type: types.CHANGE_CURRENT,
        current
    };
}