import { Map } from 'immutable';
import * as types from '../constants/ActionTypes';

const initState = new Map({
    token: null,
    id: null,
    enterpriseId: null,
    user: null,
    role: null,
    enterprise: null,
    rights: null,
    menu: null,

    isLogged: false,
    error: { code: 0, message: '' },
});


export default function authReducer(state = initState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return state.set('isLogged', false);
        case types.LOGIN_SUCCESS:
            return state
                .set('token', action.token)
                .set('id', action.id)
                .set('enterpriseId', action.enterpriseId)
                .set('user', action.user)
                .set('role', action.role)
                .set('enterprise', action.enterprise)
                .set('rights', action.rights)
                .set('menu', action.menu)
                .set('isLogged', true)
                .set('isSubmitting', false);
        case types.LOGIN_UPDATE:
            return state
                .set('token', action.token)
                .set('user', action.user ? action.user : state.get('user'))
                .set('enterprise', action.enterprise ? action.enterprise : state.get('enterprise'))
                .set('isLogged', true)
                .set('isSubmitting', false);
        case types.LOGIN_ERROR:
            return state
                .set('isLogged', false)
                .set('error', action.error);
        case types.RECOVER_SUCCESS:
            return state
                .set('isLogged', true)
                .set('error', { code: 0, message: '' });
        case types.LOGOUT:
            return initState;
        case types.UPDATE_MENU_ITEMS: {
            const menu = state.get('menu');
            if (menu[action.key]) menu[action.key] = action.items;
            return state.set('menu', menu);
        }
        case types.SET_TOKEN:
            return state
                .set('token', action.token)

        default:
            return state;
    }
}
