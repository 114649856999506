import { all, fork } from 'redux-saga/effects';

import asyncSagas from './async'
import optionSagas from './options'

export default function* rootSaga(getState) {
    yield all([
        asyncSagas(),
        optionSagas()

    ]);
}
