import React from "react";
import LoaderComponent from "./loader.style";

export default (props) => {
    return  (
        <LoaderComponent>
            <div className="k-loader k-circle"></div>
            <p>{ props.title ? <span>{props.title}</span> : '正在载入数据' }</p>
        </LoaderComponent>
    )
};
